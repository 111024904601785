import React from 'react'
import LogoutModal from '../components/modals/auth/LogoutModal'
import VaultActionModal from '../components/modals/auth/transactions/VaultActionModal'
import DepositWithdrawalModal from '../components/modals/auth/transactions/DepositWithdrawal'
import { useDispatch, useSelector } from 'react-redux'
import { logoutStart, setModalState } from '../store/redux-slices/login'
import { Routes } from '../utils/routes'
import { useNavigate } from 'react-router-dom'
import AlreadyLoginModal from '../components/modals/auth/AlreadyLoginModal'

const RenderModal = () => {
  const navigate = useNavigate()
  const { isVaultActionModalOpen } = useSelector(state => state.toggle)
  const { isLogoutOpen, client, isWalletActionOpen, alreadyLoginOpen } = useSelector((state) => state.login)
  const dispatch = useDispatch()
  const handleLogoutModal = (val) => {
    dispatch(setModalState({ key: 'isLogoutOpen', value: val }))
  }
  const logout = () => {
    dispatch(logoutStart({
      handleLogoutModal: () => {
        handleLogoutModal(false)
        navigate(Routes.homepage)
      }
    }))
    try {
      if (client) {
        client.disconnect()
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      {/* Logout modal */}
      <LogoutModal isOpen={isLogoutOpen} handleLogout={logout} />
      {/* vault */}
      <VaultActionModal isOpen={isVaultActionModalOpen} />
      {/* wallet deposit witdraw */}
      <DepositWithdrawalModal isOpen={isWalletActionOpen} />
      {/* already login modal */}
      <AlreadyLoginModal isOpen={alreadyLoginOpen} />

    </>
  )
}

export default RenderModal
