import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userVerifyPasswordStart, verifyUserStart } from '../../../store/redux-slices/login'
import { WALLET_ACTION } from '../../../utils/constants'

const useVaultAction = () => {
  const [isToViewAccNumber, setIsToViewAccNumber] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const [showAccNumber, setShowAccNumber] = useState(false)
  const { userDetails, currencies } = useSelector((state) => state.user)
  const { verifyUserLoading } = useSelector(state => state.login)
  const { vaultActionLoading } = useSelector(state => state.transactions)
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false)
  const [isPasswordModalOpenForBal, setIsPasswordModalOpenForBal] = useState(false)
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false)
  const [data, setData] = useState({})
  const [actionType, setActionType] = useState(WALLET_ACTION.DEPOSIT)
  const dispatch = useDispatch()
  const verifyUserData = {
    type: data.actionType,
    username: userDetails?.username,
    data: data,
    isVaultAction: true,
    closeModal: () => {
      setIsPasswordModalOpen(false)
    }
  }
  const handleSubmit = (values) => {
    dispatch(verifyUserStart({ ...verifyUserData, password: values.password }))
  }
  const handleBalanceShowSubmit = (values) => {
    dispatch(userVerifyPasswordStart({
      username: userDetails?.username,
      password: values.password,
      handleReset: () => {
        setIsPasswordModalOpenForBal(false)
        setIsShow(true)
      }
    }))
  }

  const handleWalletAction = (type, values) => {
    setData({ ...values, amount: Number(values.amount), actionType: type })
    setIsPasswordModalOpen(true)
  }
  return {
    handleWalletAction,
    isPasswordModalOpen,
    setIsPasswordModalOpen,
    handleSubmit,
    verifyUserLoading,
    currencies,
    userDetails,
    data,
    actionType,
    setActionType,
    vaultActionLoading,
    isVerifyModalOpen,
    setIsVerifyModalOpen,
    setData,
    setShowAccNumber,
    setIsToViewAccNumber,
    isToViewAccNumber,
    showAccNumber,
    isShow,
    setIsShow,
    isPasswordModalOpenForBal,
    setIsPasswordModalOpenForBal,
    handleBalanceShowSubmit
  }
}

export default useVaultAction
