
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import { setModalState, userLoginStart } from '../../../store/redux-slices/login'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'

const AlreadyLoginModal = ({ isOpen, handleLogout }) => {
  const { logoutLoading, username, password } = useSelector(state => state.login)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()
  const dispatch = useDispatch()
  const handleCloseModal = () => {
    dispatch(setModalState({ key: 'alreadyLoginOpen', value: false, username: '', password: '' }))
  }
  const handleLogin = () => {
    dispatch(userLoginStart({ username, password, setShowModal: () => {}, navigate, returnTo: window.locationPath || state?.navigate, isSignUp: false, closeModal: handleCloseModal, forceLogin: true }))
  }
  return (
    <Modal id='logoutmodal' show={isOpen} onHide={handleCloseModal} centered>
      <Modal.Header closeButton className='d-flex justify-content-center'>
        <Modal.Title className='w-100 text-center'>{t('alreadyLogin')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='  logout-text'>
          {t('alreadyLoginMessage')}
        </p>
        <div className='d-flex w-100 justify-content-center '>

          <button type='button' data-bs-dismiss='modal' aria-label='Close' onClick={() => handleLogin()} className='login-sure-btn'>{t('yes')} {logoutLoading && <Spinner className='ms-2' size='sm' />}  </button>
          <button type='button' data-bs-dismiss='modal' aria-label='Close' onClick={() => handleCloseModal()} className='login-sure-btn'>{t('no')} {logoutLoading && <Spinner className='ms-2' size='sm' />}  </button>
        </div>
      </Modal.Body>
    </Modal>

  )
}

export default AlreadyLoginModal
