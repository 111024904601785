import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-bootstrap/Modal'
import DepositWithdraw from '../../../Wallets/DepositWithdraw'
import { setModalState } from '../../../../store/redux-slices/login'
import { useDispatch } from 'react-redux'
const DepositWithdrawalModal = ({ isOpen }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleWalletClose = () => {
    dispatch(setModalState({ key: 'isWalletActionOpen', value: false }))
  }

  return (
    <>
      <Modal className='transaction-modal' show={isOpen} onHide={handleWalletClose} centered>
        <Modal.Header closeButton className='d-flex justify-content-center'>
          <Modal.Title className='w-100 text-center'> {t('wallet')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='modal-tabs'>
            <DepositWithdraw handleClose={handleWalletClose} />
          </div>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default DepositWithdrawalModal
