import { createSlice } from '@reduxjs/toolkit'
import { getItem } from '../../utils/storageUtils'
import { getTheme } from '../../utils/helpers'

const returnScreenBool = () => {
  const screenWidth = window.innerWidth
  const isTabScreen = screenWidth > 768 && screenWidth < 1199
  const isMobile = screenWidth < 768
  const isDesktop = screenWidth > 1199
  if (isMobile) {
    return false
  } else if (isTabScreen) {
    return false
  } else if (isDesktop) {
    return true
  }
}
const isSideBarOpen = getItem('sidebar')
  ? JSON.parse(getItem('sidebar'))
  : returnScreenBool()
const isNewNotification = getItem('isNewNotification')
  ? JSON.parse(getItem('isNewNotification'))
  : false
const theme = getTheme()

const toggleSlice = createSlice({
  name: 'toggle',
  initialState: {
    sidebarOpen: isSideBarOpen,
    isTermsAndConditionOpen: false,
    isVaultActionModalOpen: false,
    isNewNotification: isNewNotification,
    isScoreBoardDetailOpen: false,
    theme: theme,
    isEntryModalOpen: true,
    isCasinoEntryModalOpen: true,
    isPlayerLevelUp: false,
    isNewNotificationForSeason: false,
    isBetSlipOpen: false,
    isRollingActive: true,
    isNewEvent: false,
    isRollBonusOpen: false,
    isBonusDetailOpen: false
  },
  reducers: {
    setSideBarOpen: (state, { payload }) => ({
      ...state,
      sidebarOpen: payload
    }),
    setTheme: (state, { payload }) => ({
      ...state,
      theme: payload
    }),
    setToggleState: (state, { payload }) => ({
      ...state,
      [payload.key]: payload.value
    })
  }
})
export const { setSideBarOpen, setToggleState, setTheme } = toggleSlice.actions
export default toggleSlice.reducer
