import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUniqueCodesStart } from '../../store/redux-slices/user'
import { useTranslation } from 'react-i18next'
import { REACT_ICONS } from '../../utils/icons'
import { formatNumber } from '../../utils/helpers'
import Loader from '../Loader'
import LoadMore from '../LoadMore'
import './uniqueCodesList.scss'
import NoDataAvailable from '../NoDataAvailable'
import { KRW_SYMBOL } from '../../utils/constants'
const UniqueCodesList = ({ setVoucherCode, voucherCode, setIsGetVoucherAvailable, form }) => {
  const [activeTab, setActiveTab] = useState(0)
  const [query, setQuery] = useState({
    limit: 10,
    pageNo: 1,

    amount: form?.amount
  })
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { uniqueCodeBonusList, uniqueCodeBonusListLoading } = useSelector(state => state.user)
  const getUniqueCodeList = (query) => {
    dispatch(getUniqueCodesStart(query))
  }
  useEffect(() => {
    getUniqueCodeList({ ...query, isAvailable: activeTab === 0 })
  }, [activeTab, query])
  const loadMoreData = () => {
    const hasNext = uniqueCodeBonusList?.rows?.length < uniqueCodeBonusList?.count
    if (hasNext) {
      setQuery({ ...query, pageNo: query.pageNo + 1 })
    }
  }

  const handleActiveTab = (activeTab) => {
    setActiveTab(activeTab)
  }
  return (
    <div className='d-flex flex-column gap-2 unique-code-bonus-list'>
      <div
        role='button'
        style={{ fontSize: 24 }} onClick={() => {
          setIsGetVoucherAvailable(prev => !prev)
        }} className='py-1'
      >{REACT_ICONS.goBack} <span style={{ fontSize: 16, fontWeight: 500 }}>{t('selectCouponCode')}</span>
      </div>
      <div className=' gap-2 tabs-btn-wrapper'>
        <button
          onClick={() => handleActiveTab(0)}
          className={`gradient-btn ${activeTab === 0 ? 'active' : ''}`}
        >
          {t('redeemAbleCoupons')}
        </button>
        <button
          onClick={() => handleActiveTab(1)}
          className={`gradient-btn ${activeTab === 1 ? 'active' : ''}`}
        >
          {t('nonApplicableCoupons')}
        </button>
      </div>
      {
      uniqueCodeBonusList?.count > 0
        ? (
          <ul>
            {uniqueCodeBonusList?.rows?.map((voucher) => {
              return (
                <li
                  key={voucher.voucherId}
                  onClick={activeTab === 0
                    ? () => {
                        setVoucherCode(voucher)
                        setIsGetVoucherAvailable(prev => !prev)
                      }
                    : undefined}
                  className='voucher-item'
                >
                  <div className='flex-flex-grow-1'>
                    <div>
                      {t('availThisCoupon')}  &nbsp;
                      {formatNumber(voucher.targetBet)} {KRW_SYMBOL} &nbsp;
                      {t('orAbove')}  &nbsp;
                      {t('andGetBenefit')}  &nbsp;
                      {formatNumber(voucher?.voucherBenefits)} {KRW_SYMBOL}.
                    </div>
                  </div>
                  {
                  activeTab === 0 &&
                    <div className='icon-check'>
                      {voucherCode?.voucherId === voucher?.voucherId && activeTab === 0 && REACT_ICONS.check}
                    </div>
                }
                </li>
              )
            })}
          </ul>
          )
        : (
          <div className='alipy-5'>
            <NoDataAvailable errorMessage='' />
          </div>
          )
    }
      <div>
        {uniqueCodeBonusListLoading && <Loader />}
        <LoadMore
          loadMoreData={loadMoreData}
          totalCount={uniqueCodeBonusList?.count}
          renderCount={() => uniqueCodeBonusList?.rows?.length}
          show={t('coupons')}
        />
      </div>
    </div>
  )
}

export default UniqueCodesList
