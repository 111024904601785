import { takeLatest, put, select } from 'redux-saga/effects'

import {
  getUserBetHistoryStart,
  getUserBetHistorySuccess,
  getUserBetHistoryFailure,
  getUserIdAndSessionIdStart,
  getUserIdAndSessionIdSuccess,
  getUserIdAndSessionIdFailure,
  getWithdrawalHistoryStart,
  getWithdrawalHistorySuccess,
  getWithdrawalHistoryFailure,
  cancelWithdrawRequestStart,
  cancelWithdrawRequestComplete,
  getUserTransactionsStart,
  getUserTransactionsSuccess,
  getUserTransactionsFailure,
  setWalletActionStart,
  setWalletActionSuccess,
  setWalletActionFailure,
  getWalletTransactionsStart,
  getWalletTransactionsSuccess,
  getWalletTransactionsFailure,
  setVaultActionStart,
  setVaultActionSuccess,
  setVaultActionFailure,
  getVaultTransactionsStart,
  getVaultTransactionsSuccess,
  getVaultTransactionsFailure,
  getBonusTransactionsStart,
  getBonusTransactionsSuccess,
  getBonusTransactionsFailure,
  getPromotionCashTransactionStart,
  getPromotionCashTransactionSuccess,
  getPromotionCashTransactionFailure
} from '../redux-slices/transactions'

import {
  getUserBetHistory,
  getUserCredential,
  getWithdrawalHistory,
  cancelWithdrawRequest,
  getUserTransactions,
  setWalletAction,
  getWalletTransactions,
  setVaultAction,
  getVaultTransactions,
  getBonusTransaction,
  getPromotionCashTransaction
} from '../../utils/apiCalls'
import { toast } from '../../components/Toast'
import { setItem } from '../../utils/storageUtils'
import { getDateDaysAgo } from '../../utils/dateFormatter'
import { setModalState } from '../redux-slices/login'
import { t } from 'i18next'
import { setToggleState } from '../redux-slices/toggle'
import { WALLET_ACTION } from '../../utils/constants'
import { getUserDetailsStart } from '../redux-slices/user'

const selectedLangCode = state => state?.language

export default function * transactionsWatcher () {
  yield takeLatest(getPromotionCashTransactionStart?.type, getPromotionCashTransactionWorker)
  yield takeLatest(getUserBetHistoryStart?.type, getUserBetHistoryWorker)
  yield takeLatest(getUserIdAndSessionIdStart?.type, getUserIdAndSessionIdStartWorker)
  yield takeLatest(getWithdrawalHistoryStart?.type, getWithdrawalHistoryWorker)
  yield takeLatest(cancelWithdrawRequestStart?.type, cancelWithdrawRequestWorker)
  yield takeLatest(getUserTransactionsStart?.type, getUserTransactionsWorker)
  yield takeLatest(setWalletActionStart.type, setWalletActionWorker)
  yield takeLatest(setVaultActionStart.type, setVaultActionWorker)
  yield takeLatest(getWalletTransactionsStart.type, getWalletTransactionsWorker)
  yield takeLatest(getVaultTransactionsStart.type, getVaultTransactionsWorker)
  yield takeLatest(getBonusTransactionsStart.type, getBonusTransactionsWorker)
}

function * getBonusTransactionsWorker (action) {
  const { page } = action && action.payload
  try {
    const transactionData = yield select(state => state.transactions.bonusTransactions)
    const { data } = yield getBonusTransaction({ ...action.payload })

    if (page === 1) {
      yield put(getBonusTransactionsSuccess(data?.data?.userBonusTransactionService))
    } else {
      const tempData = {
        count: data?.data?.userBonusTransactionService?.count,
        rows: [...transactionData?.rows, ...data?.data?.userBonusTransactionService?.rows]
      }
      yield put(getBonusTransactionsSuccess(tempData))
    }
  } catch (e) {
    yield put(getBonusTransactionsFailure())
  }
}
function * setWalletActionWorker (action) {
  try {
    const { handleReset } = action.payload
    const res = yield setWalletAction({ data: action.payload.data })
    if (res.status === 200) {
      toast(`${t('requestCreatedSuccess')}`, 'success')
      yield put(setModalState({ key: 'isWalletActionOpen', value: false }))
      if (action.payload?.data?.actionSource === WALLET_ACTION.WITHDRAW) {
        yield put(getUserDetailsStart(window.location.pathname))
      }
      yield handleReset()
    }
    yield put(setWalletActionSuccess())
  } catch (e) {
    if (e?.response?.data?.errors[0]?.name === 'WalletActionSourceAlreadyExists') {
      toast(`${t('walletActionSourceAlreadyExists')}`, 'error')
    } else if (e?.response?.data?.errors[0]?.name === 'InsufficientBalance') {
      toast(`${t('insufficientBalance')}`, 'error')
    } else {
      toast(e?.response?.data?.errors[0]?.description, 'error')
    }
    yield put(setWalletActionFailure())
  }
}
function * setVaultActionWorker (action) {
  const { actionType } = action.payload
  try {
    const res = yield setVaultAction({ data: action.payload })
    if (res.status === 200) {
      if (actionType === WALLET_ACTION.DEPOSIT) {
        toast(`${t('vaultDepositSuccess')}`, 'success')
      } else {
        toast(`${t('vaultWithdrawSuccess')}`, 'success')
      }
      yield put(setToggleState({ key: 'isVaultActionModalOpen', value: false }))
      yield put(getUserDetailsStart(window.location.pathname))
    }
    yield put(setVaultActionSuccess())
  } catch (e) {
    toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(setVaultActionFailure())
  }
}
function * getWalletTransactionsWorker (action) {
  const { page } = action && action.payload
  try {
    const transactionData = yield select(state => state.transactions.walletTransactions)
    const { data } = yield getWalletTransactions({ ...action.payload })

    if (page === 1) {
      yield put(getWalletTransactionsSuccess(data?.data?.walletTransaction))
    } else {
      const tempData = {
        count: data?.data?.walletTransaction?.count,
        rows: [...transactionData?.rows, ...data?.data?.walletTransaction?.rows]
      }
      yield put(getWalletTransactionsSuccess(tempData))
    }
  } catch (e) {
    yield put(getWalletTransactionsFailure())
  }
}
function * getPromotionCashTransactionWorker (action) {
  const { page } = action && action.payload
  try {
    const transactionData = yield select(state => state.transactions.promotionCashTransactions)
    const { data } = yield getPromotionCashTransaction({ ...action.payload })

    if (page === 1) {
      yield put(getPromotionCashTransactionSuccess(data?.data?.walletTransaction))
    } else {
      const tempData = {
        count: data?.data?.walletTransaction?.count,
        rows: [...transactionData?.rows, ...data?.data?.walletTransaction?.rows]
      }
      yield put(getPromotionCashTransactionSuccess(tempData))
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getPromotionCashTransactionFailure())
  }
}
function * getVaultTransactionsWorker (action) {
  const { page } = action && action.payload
  try {
    const transactionData = yield select(state => state.transactions.vaultTransactions)
    const { data } = yield getVaultTransactions({ ...action.payload })

    if (page === 1) {
      yield put(getVaultTransactionsSuccess(data?.data?.vaultTransactions))
    } else {
      const tempData = {
        count: data?.data?.vaultTransactions?.count,
        rows: [...transactionData?.rows, ...data?.data?.vaultTransactions?.rows]
      }
      yield put(getVaultTransactionsSuccess(tempData))
    }
  } catch (e) {
    yield put(getVaultTransactionsFailure())
  }
}
function * getUserBetHistoryWorker (action) {
  try {
    const { limit, pageNo, search, startDate, endDate, actionType } = action && action.payload

    const { data } = yield getUserBetHistory({ limit, pageNo, search, startDate, endDate, actionType })

    yield put(getUserBetHistorySuccess(data?.data?.casinoTransaction))
  } catch (e) {
    yield put(getUserBetHistoryFailure())
  }
}

function * getUserIdAndSessionIdStartWorker (action) {
  try {
    const { type } = action && action.payload

    const { data } = yield getUserCredential(type)

    setItem('sessionId', data?.data?.sessionId)
    yield put(getUserIdAndSessionIdSuccess(data?.data))
  } catch (e) {
    const { selectedLanguageCode } = yield select(selectedLangCode)
    const { navigate } = action && action.payload

    yield put(getUserIdAndSessionIdFailure())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    if (e?.response?.data?.errors[0]?.errorCode === 3054) {
      navigate(`/${(selectedLanguageCode?.toLowerCase())}/account/bonus`, { state: { tab: 'bonus' } })
    } else {
      navigate(`/${(selectedLanguageCode?.toLowerCase())}/account/account-verify`, { state: { tab: 'account-verify' } })
    }
  }
}

function * getWithdrawalHistoryWorker (action) {
  try {
    const { limit, pageNo, status, startDate, endDate, search } = action && action.payload

    const { data } = yield getWithdrawalHistory({ limit, pageNo, status, startDate, endDate, search })

    yield put(getWithdrawalHistorySuccess(data?.data?.withdrawRequest))
  } catch (e) {
    yield put(getWithdrawalHistoryFailure())
  }
}

function * cancelWithdrawRequestWorker (action) {
  try {
    const { data, limit, pageNo, status, search, isTransactions = false, startDate, endDate, transactionType } = action && action.payload

    yield cancelWithdrawRequest(data)

    yield put(cancelWithdrawRequestComplete())

    if (isTransactions) {
      yield put(getUserTransactionsStart({ limit, pageNo, status, startDate, endDate, transactionType, search }))
    } else {
      yield put(getWithdrawalHistoryStart({ limit, pageNo, status, startDate: getDateDaysAgo(10), endDate: new Date(), search }))
    }
  } catch (e) {
    yield put(cancelWithdrawRequestComplete())
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * getUserTransactionsWorker (action) {
  try {
    const { limit, pageNo, startDate, endDate, actionType, search } = action && action.payload

    const { data } = yield getUserTransactions({ limit, pageNo, startDate, endDate, actionType, search })
    yield put(getUserTransactionsSuccess(data?.data?.casinoTransaction))
  } catch (e) {
    yield put(getUserTransactionsFailure())
  }
}
