import { combineReducers } from '@reduxjs/toolkit'
import { removeLoginToken } from '../../utils/storageUtils'

import loginReducer from './login'
import casinoMenuReducer from './casinoMenu'
import transactionsReducer from './transactions'
import languageReducer from './language'
import loaderReducer from './loader'
import userReducer from './user'
import betsReducer from './bets'
import cmsReducer from './cms'
import toggleReducer from './toggle'
import sportsReducer from './sports'
import voucherReducer from './voucher'
import bonusReducer from './bonus'

export const resetState = () => ({
  type: 'resetState'
})

const appReducer = combineReducers({
  login: loginReducer,
  user: userReducer,
  casinoMenu: casinoMenuReducer,
  language: languageReducer,
  transactions: transactionsReducer,
  loader: loaderReducer,
  bets: betsReducer,
  bonus: bonusReducer,
  cms: cmsReducer,
  toggle: toggleReducer,
  sports: sportsReducer,
  voucher: voucherReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'resetState') {
    removeLoginToken()
    return appReducer({ ...state, user: { ...state?.user, userDetails: null } }, action)
  }

  return appReducer(state, action)
}

export default rootReducer
