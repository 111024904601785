import React, { useState, useEffect, useMemo } from 'react'
import { format } from 'date-fns'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import './lockedFund.scss'
import SelectField from '../../form-elements/SelectField/SelectField'
import { useDispatch, useSelector } from 'react-redux'
import { getRollOverDataStart } from '../../../store/redux-slices/bonus'
import Loader from '../../Loader'
import NoDataAvailable from '../../NoDataAvailable'
import { formatNumber } from '../../../utils/helpers'
import InfiniteScrollComponent from '../../InfiniteScrollWrapper/InfiniteScrollComponent'
import { REACT_ICONS } from '../../../utils/icons'
import { KRW_SYMBOL, ROLLING_CONSTANT, ROLLING_TRANSLATION_KEY } from '../../../utils/constants'

const bonusOptions = [
  {
    label: 'all',
    value: 'all'
  },
  {
    label: 'complete',
    value: 'complete'
  },
  {
    label: 'inprogress',
    value: 'inprogress'
  },
  {
    label: 'notStartedRollStatus',
    value: 'notStarted'
  }

]
const rollOverTypeConstants = [
  {
    label: 'depositRolling',
    value: ROLLING_CONSTANT.depositRolling
  },
  {
    label: 'promotionCashRolling',
    value: ROLLING_CONSTANT.promotionCashRolling
  },
  {
    label: 'bonusRolling',
    value: ROLLING_CONSTANT.bonusRolling
  },
  {
    label: 'vouchersRolling',
    value: ROLLING_CONSTANT.vouchersRolling
  },
  {
    label: 'giftCardsRolling',
    value: ROLLING_CONSTANT.giftCardsRolling
  },
  {
    label: 'seasonsRolling',
    value: ROLLING_CONSTANT.seasonsRolling
  }

]

const SingleInfo = ({ data, t, rollType }) => {
  const isNotDeposit = rollType !== 'Deposit Rolling'
  const isBonus = rollType === 'Bonus Rolling'
  return (
    <div className='single-info'>
      <div className='d-flex justify-content-between align-items-start'>
        <div>
          <div className=''>{isBonus ? t(data?.bonusType) : t(ROLLING_TRANSLATION_KEY[rollType])}</div>
          <span className='text-muted'>{format(new Date(data.createdAt), 'MM-dd-yyyy hh:mm a')}</span>
        </div>
        <div>
          <div className='amount d-block win-amt '> +   {isNotDeposit ? formatNumber(Number(data?.payout) + Number(data?.remainingAmount)) : formatNumber(data?.remainingAmount)} {KRW_SYMBOL}</div>
          <div className='amount'>{formatNumber(data?.wageredAmount)}/{formatNumber(data?.eligibleAmount)} {KRW_SYMBOL}</div>
        </div>

      </div>
    </div>
  )
}
export const LockedFundProgress = ({ isOpen, handleCloseModal }) => {
  const limit = 5
  const { t } = useTranslation()
  const [rollType, setRollType] = useState(rollOverTypeConstants?.[0]?.value)
  const [rollStatus, setRollStatus] = useState(bonusOptions?.[0]?.value)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const {
    // rollOverType,
    rollOverData,
    rollOverDataLoading
    // rollOverTypeLoading
  } = useSelector(state => state.bonus)

  const rollTypesArr = useMemo(() => {
    return rollOverTypeConstants?.map((item) => ({ label: t(item?.label), value: item?.value }))
  }, [rollOverTypeConstants])

  const rollStatusArr = useMemo(() => {
    return bonusOptions?.map((item) => ({ label: t(item?.label), value: item?.value }))
  }, [bonusOptions])

  const loadMoreData = () => {
    const count = rollOverData?.count
    const totalPages = Math.ceil(count / limit)
    if (page < Number(totalPages)) { setPage(page + 1) }
  }
  useEffect(() => {
    dispatch(getRollOverDataStart({
      formData: { type: rollType, limit: limit, page: page, status: rollStatus }
    }))
  }, [rollType, page, rollStatus])

  const handleRefresh = () => {
    setPage(1)
    setRollType(rollOverTypeConstants?.[0]?.value)
    setRollStatus(bonusOptions?.[0]?.value)
  }
  return (
    <Modal dialogClassName='locked-bonus-detail' show={isOpen} onHide={handleCloseModal} centered size='md'>
      <Modal.Header className='d-flex justify-content-center flex-grow-1' closeButton>
        <Modal.Title className='text-center flex-grow-1'> <h4>{t('rollOver')}</h4></Modal.Title>
      </Modal.Header>
      <Modal.Body className=''>
        <div className='content-wrapper'>
          <div className=' mb-4'>

            <div className='d-flex align-items-center gap-2'>
              <div className='flex-grow-1'>
                <SelectField
                  value={rollType}
                  onChange={(e) => {
                    setRollType(e.target.value)
                    setPage(1)
                  }}
                  isNonFormik
                  options={rollTypesArr}
                />
              </div>
              <div className='flex-grow-1'>
                <SelectField
                  value={rollStatus}
                  defaultValue={rollStatus}
                  onChange={(e) => {
                    setRollStatus(e.target.value)
                    setPage(1)
                  }}
                  isNonFormik
                  options={rollStatusArr}
                />
              </div>
              <div onClick={handleRefresh} role='button' className='refresh-icon'>{REACT_ICONS?.refresh}</div>
            </div>

          </div>
          <div>
            {rollType
              ? (
                <>

                  <div className='overview-wrapper'>
                    <div className='d-flex justify-content-between header'>
                      <span className='text-muted'>{t('rollOverType')}</span>
                      <span className='text-muted'>{t('amount')}</span>
                    </div>
                    <div id='rollTarget' className='my-2 scrollable-content'>
                      <InfiniteScrollComponent
                        hasMore={rollOverData?.rows?.length < rollOverData?.count}
                        fetchData={loadMoreData}
                        length={rollOverData?.rows?.length}
                        limit={rollOverData?.rows ? rollOverData?.rows?.length : limit}
                        totalPages={Math.ceil(rollOverData?.count / limit)}
                        page={page}
                        scrollableTarget='rollTarget'
                      >

                        {rollOverData?.count > 0
                          ? rollOverData?.rows?.map((item) => {
                              return <SingleInfo key={item.mappingId} t={t} data={item} rollType={rollType} />
                            })
                          : (
                              !rollOverDataLoading && <NoDataAvailable className='py-2' errorMessage='noDataFound' />
                            )}

                      </InfiniteScrollComponent>
                      {

                        rollOverDataLoading &&
                          <Loader />

                      }
                    </div>

                  </div>

                </>
                )
              : (
                <p className='text-center py-2'>{t('pleaseSelectRollType')}</p>
                )}

          </div>

        </div>
      </Modal.Body>

    </Modal>
  )
}
