import * as Yup from 'yup'
import { WALLET_ACTION } from '../../utils/constants'
import { formatNumber } from '../../utils/helpers'

// Schema for EN with Extra Validations
export const transactionValiation = (t, actionType, amount) =>
  Yup.object().shape({
    amount: WALLET_ACTION.WITHDRAW === actionType
      ? Yup.string()
          .required(t('amountRequired')) // Ensure the field is not empty
          .test('is-number', t('mustBeNumber'), value => {
            return !isNaN(value) && Number(value) > 0 // Ensure value is a positive number
          })
          .test('is-integer', t('mustBeInteger'), value => {
            return /^\d+$/.test(value) // Ensure no decimal points
          })
          .test('min-value', t('min10000Required'), value => {
            return Number(value) >= 10000 // Ensure value is at least 10000
          })
          .test('max-value', `${t('maxAmountEnteredis')} ${formatNumber(amount)} (${t('requestErrorMessage')})`, value => {
            return Number(value) <= amount // Ensure value is less than or equal to the maximum amount
          })
          .test('is-multiple-of-10000', t('mustBeMultipleOf10000'), value => {
            return Number(value) % 10000 === 0 // Ensure value is a multiple of 10000
          })
      : Yup.string()
        .required(t('amountRequired')) // Ensure the field is not empty
        .test('is-number', t('mustBeNumber'), value => {
          return !isNaN(value) && Number(value) > 0 // Ensure value is a positive number
        })
        .test('is-integer', t('mustBeInteger'), value => {
          return /^\d+$/.test(value) // Ensure no decimal points
        })
        .test('min-value', t('min10000Required'), value => {
          return Number(value) >= 10000 // Ensure value is at least 10000
        })
        .test('is-multiple-of-10000', t('mustBeMultipleOf10000'), value => {
          return Number(value) % 10000 === 0 // Ensure value is a multiple of 10000
        }),
    currency: Yup.string()
      .required(t('currencyRequired')) //

  })

export const vaultTransactionValiation = (t, type, amount) => {
  console.log('amount: ', amount)
  console.log('type: ', type)
  return Yup.object().shape({
    amount:
    Yup.string()
      .required(t('amountRequired')) // Ensure the field is not empty
      .test('is-number', t('mustBeNumber'), value => {
        return !isNaN(value) && Number(value) > 0 // Ensure value is a positive number
      })
      .test('is-integer', t('mustBeInteger'), value => {
        return /^\d+$/.test(value) // Ensure no decimal points
      })
      .test('min-value', t('min10000Required'), value => {
        return Number(value) >= 10000 // Ensure value is at least 10000
      })
      .test('max-value', `${t('maxAmountEnteredis')} ${formatNumber(amount)} (${t(type?.toLowerCase())} ${t('requestErrorMessage')})`, value => {
        return Number(value) <= amount // Ensure value is less than or equal to the maximum amount
      })
      .test('is-multiple-of-10000', t('mustBeMultipleOf10000'), value => {
        return Number(value) % 10000 === 0 // Ensure value is a multiple of 10000
      }),
    currency:
      Yup.string()
        .required(t('currencyRequired'))

  })
}
