import React from 'react'
import { useTranslation } from 'react-i18next'

const NoDataAvailable = ({ errorMessage, className }) => {
  const { t } = useTranslation()
  return (
    <>
      <div className={`not-found-box ${className}`}>
        <div className='text-box'>
          <p className='text-center mb-0'>{t(errorMessage) || t('noDataFound')}</p>
        </div>
      </div>
    </>
  )
}

export default NoDataAvailable
