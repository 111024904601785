import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { THEMES } from './constants'
import { getItem, setItem } from './storageUtils'

export const capitalizeMyText = (name, delemiter) => {
  return (name && name?.length > 0 && name.split(delemiter).map(ele =>
    ele.substr(0, 1).toUpperCase().concat(ele.substr(1))
  ).join(' ')) || name
}

export const countDown = (startMinutes = 1, msg, setValue, windowPopup) => {
  let time = startMinutes * 60 - 1 // Start From 59th second

  const t = setInterval(() => {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60
    const result = `${msg} ${String(minutes)?.length === 1 ? `0${minutes}` : minutes}:${String(seconds)?.length === 1 ? `0${seconds}` : seconds}`
    setValue(result) // Update value using ref
    time--
    if (minutes === 0 && seconds === 0) {
      clearInterval(t)
      windowPopup && windowPopup?.close()
    }
  }, 1000)
}

export const ScrollToTop = () => {
  const { pathname } = useLocation()
  const [isScriptEnabled, setScriptEnabled] = useState(false)

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    })
    if (pathname.includes('betby-sports') && !window.betByInit) {
      setScriptEnabled(true)
    }
  }, [pathname])

  useEffect(() => {
    if (isScriptEnabled) {
      const script = document.createElement('script')
      script.src = process.env.REACT_APP_BETBY_SCRIPT
      script.async = true

      script.onload = () => {
        if (window.BTRenderer) {
          const BetByRenderer = new window.BTRenderer()
          window.betByInit = BetByRenderer
          if (window.setBetByInit) { window.setBetByInit(BetByRenderer) }
        }
      }
      script.onerror = (error) => {
        console.error('BetBy Script failed to load', error)
      }

      document.head.appendChild(script)
    }
  }, [isScriptEnabled])

  useEffect(() => {
    console.warn = () => {}
    console.error = () => {}
  }, [])

  return null
}

export const isNewGame = (date) => {
  if (date) {
    const currentDate = new Date()
    const gameUpdatedAt = new Date(date)
    const diff = parseInt((currentDate - gameUpdatedAt) / (1000 * 60 * 60 * 24), 10)
    if (diff <= 30 && diff > 0) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
export const searchParamsObject = (searchParams) => {
  return Object.fromEntries(searchParams)
}
export const formatNumber = (number = 0) => {
  return parseFloat(parseFloat(number || 0).toFixed(2)).toLocaleString(
    'en-US',
    {
      useGrouping: true
    }
  )
}

export const getTheme = () => {
  if (getItem('theme')) {
    document.documentElement.removeAttribute('class')
    document.documentElement.classList.add(JSON.parse(getItem('theme')))
    setItem('theme', JSON.stringify(JSON.parse(getItem('theme'))))
    return JSON.parse(getItem('theme'))
  } else {
    document.documentElement.classList.add(THEMES.DARK)
    setItem('theme', JSON.stringify(THEMES.DARK))
    return THEMES.DARK
  }
}

export const isSportsBook = () => {
  const url = window.location.href
  if (url.includes('sportsbook')) {
    return true
  } else {
    return false
  }
}

export const countDecimals = (number) => {
  const x = Number(number)
  if (Math.floor(x.valueOf()) === x.valueOf()) return 0
  const str = x.toString()
  if (str.indexOf('.') !== -1 && str.indexOf('-') !== -1) {
    return str.split('-')[1] || 0
  } else if (str.indexOf('.') !== -1) {
    return str.split('.')[1].length || 0
  }
  return str.split('-')[1] || 0
}
export const returnTeamsURL = (teams, type) => {
  const team1 = teams?.filter((item) => item?.type === 'home')?.[0]?.team?.imageUrl
  const team2 = teams?.filter((item) => item?.type === 'away')?.[0]?.team?.imageUrl

  const returnImageUrl = (urlOject) => {
    if (!urlOject) {
      return null
    }
    if (urlOject[type]) {
      return urlOject[type]
    } else {
      return null
    }
  }
  return {
    team1URL: returnImageUrl(team1),
    team2URL: returnImageUrl(team2)
  }
}

export const removeParam = (key, searchParams, setSearchParams) => {
  const newParams = new URLSearchParams(searchParams)
  newParams.delete(key)
  setSearchParams(newParams, { replace: true })
}

export const addParam = (key, value, searchParams, setSearchParams) => {
  const newParams = new URLSearchParams(searchParams)
  newParams.set(key, value)
  setSearchParams(newParams, { replace: true })
}

const origin = window?.location?.origin

export const isNotProduction =
  origin.includes('staging') ||
  origin.includes('dev') ||
  origin.includes('local')

export const navigateToBetBySports = (navigate) => {
  if (window?.updateOptions) {
    window?.updateOptions({ url: '/' })
  }
}
