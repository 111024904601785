import CryptoJS from 'crypto-js'
import i18n from 'i18next'

const SOCKET_ENCRYPTION_KEY = process.env.REACT_APP_SOCKET_ENCRYPTION_KEY

const FE_ENCRYPTION_KEY = process.env.REACT_APP_FE_ENCRYPTION_KEY

export const encryptCredentials = (data) => {
  return CryptoJS.AES.encrypt(data, FE_ENCRYPTION_KEY).toString()
}

export const decryptCredentials = (data) => {
  return CryptoJS.AES.decrypt(data, FE_ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8)
}

export const encryptSocketData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SOCKET_ENCRYPTION_KEY).toString()
}

export const encryptSocketToken = (token) => {
  return CryptoJS.AES.encrypt(token, SOCKET_ENCRYPTION_KEY).toString()
}

export const decryptSocketData = (data) => {
  return JSON.parse(CryptoJS.AES.decrypt(data, SOCKET_ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8))
}

export const setLoginToken = (token) =>
  window.localStorage.setItem('access-token', encryptCredentials(token))

export const setRefreshToken = (token) =>
  window.localStorage.setItem('refresh-token', encryptCredentials(token))

export const getLoginTokenForSocket = () => {
  if (getItem('access-token')) {
    return encryptSocketToken(getItem('access-token'))
  }
  return ''
}

export const getLoginToken = () => {
  if (getItem('access-token')) {
    return getItem('access-token')
  }
  return ''
}
export const getRefreshToken = () => {
  if (getItem('refresh-token')) {
    return getItem('refresh-token')
  }
  return ''
}

export const removeLoginToken = () => {
  const languageCode = getItem('language')
  removeItem('refresh-token')
  removeItem('access-token')
  removeItem('userId')
  removeItem('loggedIn')
  removeItem('sportsbookVisible')
  setItem('language', languageCode)
  window.location.href = '/'
}

export const setItem = (key, value) => {
  if (key === 'language') i18n?.changeLanguage(value?.toUpperCase())
  return window.localStorage.setItem(key, encryptCredentials(value))
}

export const getItem = (key) => {
  if (!window?.localStorage?.getItem(key)) {
    return window.localStorage.getItem(key)
  }
  if (key === 'userId') {
    return parseInt(decryptCredentials(window.localStorage.getItem(key)))
  }
  if (key === 'loggedIn') {
    return Boolean(decryptCredentials(window.localStorage.getItem(key)))
  }
  return decryptCredentials(window.localStorage.getItem(key))
}

export const removeItem = (key) => {
  if (window?.localStorage?.getItem(key)) {
    window.localStorage.removeItem(key)
  }
}

export const getCookie = (name = 'refToken') => {
  let value = ''
  const cookie = document.cookie
  const parts = cookie.split('; ')
  for (const i in parts) {
    if (value) return value
    if (parts[i]?.match(name)) {
      value = parts[i]?.split(`${name}=`)?.[1]
    }
  }
  return value
}

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  const expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export const deleteCookie = (cname) => {
  const d = new Date()
  d.setTime(d.getTime() + (24 * 60 * 60 * 1000))
  const expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=;' + expires + ';path=/'
}

export const getDocumentCookie = (cname) => {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
export const getLanguage = () => {
  return getItem('language')?.toLowerCase() || 'en'
}
