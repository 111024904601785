export const SPORTS_LIMIT = 12
export const BET_AMOUNT_ARRAY = [1000, 5000, 10000]
export const BET_AMOUNT_ARRAY_TOP_MATCH = [200, 500, 1000, 5000]
export const BET_AMOUNT_ARRAY_HOT_COMBO = [200, 500, 1000, 2000, 5000]
export const EVENT_LISTENERS = {
  ROLL_EMITTER: 'ROLL_EMITTER',
  DISCONNECT: 'disconnect',
  CONNECT: 'connect',
  ERROR: 'error',
  USER_WALLET_BALANCE: 'USER_WALLET_BALANCE',
  ADMIN_USER_WALLET_BALANCE: 'ADMIN_USER_WALLET_BALANCE',
  LIVE_PLAYER_COUNT: 'LIVE_PLAYER_COUNT',
  NOTIFICATION: 'NOTIFICATION',
  UNIQUE_NUMBER_BONUS: 'UNIQUE_NUMBER_BONUS',
  WALLET_NOTIFICATION: 'WALLET_NOTIFICATION',
  PLAYER_LEVEL: 'PLAYER_LEVEL',
  VOUCHER_EMITTER: 'VOUCHER_EMITTER',
  BETRADAR_ODDS_CHANGE: 'BETRADAR_ODDS_CHANGE',
  BETRADAR_FIXTURE_CHANGE: 'betradarFixtureChange',
  BETRADAR_BET_SETTLEMENT: 'BETRADAR_BET_SETTLEMENT',
  BETRADAR_ROLLBACK_BET_SETTLEMENT: 'BETRADAR_ROLLBACK_BET_SETTLEMENT',
  BETRADAR_BET_UPDATES: 'BETRADAR_BET_UPDATE',
  BETRADAR_BET_STOP: 'BETRADAR_BET_STOP',
  NEW_EVENT: 'NEW_EVENT',
  BONUS_NOTIFICATION: 'BONUS_NOTIFICATION',
  BETRADAR_CONNECTION_STATUS: 'BETRADAR_CONNECTION_STATUS',
  USER_SESSION_ACTIVITY: 'USER_SESSION_ACTIVITY'
}

export const gammastackDemoURL = [
  'https://staging.d3a5rsc2g45so8.amplifyapp.com/',
  'tenant-first-staging.dinocasino.com'
]

export const isGamma = window.location.origin.includes('staging.d3a5rsc2g45so8.amplifyapp.com')
export const BONUS_TRANSACTION_DETAILS = {
  'HOT-TIME-BONUS-DEPOSIT': 'hotTimeBonus',
  'COMPENSATION-BONUS-DEPOSIT': 'compensationBonus',
  'REOCCURING-BONUS-DEPOSIT': 'reoccuringBonus',
  'FIRST-DEPOSIT-BONUS-DEPOSIT': 'firstDepositBonus'
}
export const BONUSES = {
  hotTimeBonus: 'HOT-TIME-BONUS',
  reOccuringDepositBonus: 'RE-OCCURING-DEPOSIT-BONUS',
  firstDepositBonus: 'FIRST-DEPOSIT-BONUS'
  // paybackBonus: 'PAYBACK-BONUS'
}
export const ALL_BONUSES = {
  hotTimeBonus: 'HOT-TIME-BONUS',
  reOccuringDepositBonus: 'RE-OCCURING-DEPOSIT-BONUS',
  firstDepositBonus: 'FIRST-DEPOSIT-BONUS',
  paybackBonus: 'PAYBACK-BONUS',
  compensationBonus: 'COMPENSATION-BONUS'
}
export const BET_TYPE = {
  SINGLE: {
    name: 'single',
    value: '1'
  },
  COMBO: {
    name: 'combo',
    value: '2'
  },
  CUSTOM: {
    name: 'customBet',
    value: '3'
  }
}
export const SPORTS_HOME_TABS = {
  LIVE: 'live',
  UPCOMING: 'upcoming',
  TOP: 'top'
}

export const FAV_TABS = {
  LIVE: 'live',
  UPCOMING: 'upcoming'
}

export const SOCKET_ROOM_NAME = {
  ROLL_BONUS: 'roll-bonus',
  WALLET: 'wallet',
  ADMIN_WALLET: 'admin-wallet',
  LIVE_PLAYERS: 'live-players',
  NOTIFICATION: 'notification',
  UNIQUE_NUMBER_BONUS_WINNER: 'unique-number-bonus-winner',
  WALLET_NOTIFICATION: 'wallet_notification',
  PLAYER_LEVEL: 'player-level',
  VOUCHER: 'voucher',
  BETRADAR_SPORT_UPDATES: 'betradar-sport-updates',
  BETRADAR_BET_UPDATES: 'betradar-bet-updates',
  NEW_EVENT: 'new-event',
  BONUS_NOTIFICATION: 'bonus_notification',
  BETRADAR_FIXTURE_UPDATES: '',
  USER_ACTIVITY: 'user-activity'
}
// Note please don't change the keys order of Theme object.
export const THEMES = {
  LIGHT: 'light',
  DARK: 'dark'
  // SYSTEM: 'system',
  // CUSTOM: 'custom'
}

export const NOTIFICATION_REFERENCES = {
  a: 'EMERGENCY',
  b: 'MAINTENANCE',
  c: 'Bonus',
  d: 'WALLET',
  e: 'UNIQUE-NUMBER-BONUS',
  f: 'SportsBook',
  g: 'VOUCHER',
  h: 'DEPOSIT',
  i: 'WITHDRAWAL'
}

export const NOTIFICATION_ICONS = {
  [NOTIFICATION_REFERENCES.a]: '/icon/emergency.png',
  [NOTIFICATION_REFERENCES.b]: '/icon/maintenance.png',
  [NOTIFICATION_REFERENCES.c]: '/icon/bonus.png',
  [NOTIFICATION_REFERENCES.h]: '/icon/wallet.png',
  [NOTIFICATION_REFERENCES.i]: '/icon/wallet.png',
  [NOTIFICATION_REFERENCES.d]: '/icon/wallet.png',
  [NOTIFICATION_REFERENCES.e]: '/icon/bonus.png',
  [NOTIFICATION_REFERENCES.f]: '/icon/bonus.png',
  [NOTIFICATION_REFERENCES.g]: '/icon/coupon.png'
}

export const initialLang = 'English'
export const initialLangCode = 'EN'

// export const initialLang = 'Korean'
// export const initialLangCode = 'KO'

export const TRANSACTIONS_VAULT_TYPE = {
  WALLET: 'WALLET',
  VAULT: 'VAULT',
  BONUS: 'BONUS',
  PROMOTION_CASH: 'PROMOTION_CASH'
}

export const WALLET_ACTION = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAW: 'WITHDRAW'
}
export const VAULT_ACTION_TYPE = {
  DEPOSIT: 'VAULT-DEPOSIT',
  WITHDRAW: 'VAULT-WITHDRAW'
}
export const TRANSACTIONS_TAB_MAP = {
  1: 'DEPOSIT',
  2: 'WITHDRAW'
}
export const BONUS_TAB_MAP = {
  1: 'hot_time_bonus',
  2: 're_occurring_bonus',
  3: 'compensation_bonus',
  4: 'first_deposit_bonus'
}
export const LANGUAGE_LIST = {
  EN: 'English',
  KO: '한국어',
  JA: '日本'
}
export const KYC_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED'
}

export const SORT = {
  ASC: 'asc',
  DESC: 'desc'
}

export const ICONS = {
  3: '/icon/Blackjack.svg',
  6: '/icon/Roulette.svg',
  2: '/icon/Slot.svg',
  4: '/icon/Live-Games.svg',
  5: '/icon/Baccarat.svg',
  1: '/icon/Scratchcard.svg',
  8: '/icon/fire.svg',
  9: '/icon/new.svg',
  10: '/icon/gamble.svg',
  11: '/icon/poker.svg'
}

export const USER_RANKS = {
  1: '/images/level/bronze.png',
  2: '/images/level/silver.png',
  3: '/images/level/gold.png',
  4: '/images/level/platinum1.png',
  5: '/images/level/platinum2.png',
  6: '/images/level/platinum3.png',
  7: '/images/level/platinum4.png',
  8: '/images/level/platinum5.png',
  9: '/images/level/platinum6.png',
  10: '/images/level/diamond1.png',
  11: '/images/level/diamond2.png',
  12: '/images/level/diamond3.png',
  13: '/images/level/diamond4.png'
}

export const scheduledTime = [
  {
    sportTime: 'all',
    value: 'all'
  },
  {
    sportTime: '1H',
    value: 1
  },
  {
    sportTime: '2HS',
    value: 2
  },
  {
    sportTime: '3HS',
    value: 3
  },
  {
    sportTime: '6HS',
    value: 6
  },
  {
    sportTime: '12HS',
    value: 12
  },
  {
    sportTime: '24HS',
    value: 24
  },
  {
    sportTime: '48HS',
    value: 48
  }
]

export const SOCKET_EMMITER = {
  ODDS_CHANGE: 'oddsChange',
  FIXTURE_CHANGE: 'fixtureChange',
  BET_SETTLEMENT: 'betSettlement',
  BET_STOP: 'betStop',
  ROLLBACK_BET_SETTLEMENT: 'rollbackBetSettlement',
  JOIN: 'join',
  LEAVE: 'leave',
  SESSION_ROOMS: 'joindRooms',
  CONNECTION_STATUS: 'connectionStatus',
  OUTCOME_PLAYER_NAME: 'outcomePlayerName'
}
export const BONUS_KEYS = {
  'WINNING-BONUS': '/images/bonus/winningBonus.png',
  'BETTING-BONUS': '/images/bonus/betBonus.png',
  'MIXED-MATCH': '/images/bonus/mixedBonus.png',
  'BET-COMPENSATION': '/images/bonus/compensationBonus.png',
  'COMPENSATION-BONUS': '/images/bonus/compensationBonus.png'
}
export const MILLISECONDS = 1800000 // 30 minutes in milliseconds
export const USER_BONUS_TYPE_FILTER = {
  hot_time_bonus: 'HOT-TIME-BONUS',
  compensation_bonus: 'COMPENSATION-BONUS',
  re_occurring_bonus: 'RE-OCCURING-DEPOSIT-BONUS',
  first_deposit_bonus: 'FIRST-DEPOSIT-BONUS',
  unique_ticket_bonus: 'UNIQUE-TICKET-BONUS',
  rackback_bonus: 'RACKBACK-BONUS',
  daily_periodic_payback_banus: 'DAILY-PERIODIC-PAYBACK-BONUS',
  bi_weekly_periodic_payback_bonus: 'BI-WEEKLY-PERIODIC-PAYBACK-BONUS',
  weekly_periodic_payback_bonus: 'WEEKLY-PERIODIC-PAYBACK-BONUS',
  monthly_periodic_payback_bonus: 'MONTHLY-PERIODIC-PAYBACK-BONUS'
}

export const UPDATED_USER_BONUS_TYPE_FILTER = {
  compensation_bonus: 'COMPENSATION-BONUS',
  rackback_bonus: 'RACKBACK-BONUS',
  daily_periodic_payback_banus: 'DAILY-PERIODIC-PAYBACK-BONUS',
  bi_weekly_periodic_payback_bonus: 'BI-WEEKLY-PERIODIC-PAYBACK-BONUS',
  weekly_periodic_payback_bonus: 'WEEKLY-PERIODIC-PAYBACK-BONUS',
  monthly_periodic_payback_bonus: 'MONTHLY-PERIODIC-PAYBACK-BONUS'
}

export const USER_BONUS_TYPE_VALUES = {
  HotTimeBonusAmount: 'HOT-TIME-BONUS',
  CompensationBonusAmount: 'COMPENSATION-BONUS',
  ReOccuringDepositBonusAmount: 'RE-OCCURING-DEPOSIT-BONUS',
  FirstDepositBonusAmount: 'FIRST-DEPOSIT-BONUS',
  UniqueTicketBonusAmount: 'UNIQUE-TICKET-BONUS',
  RackbackBonusAmount: 'RACKBACK-BONUS',
  DailyPeriodicPaybackBonusAmount: 'DAILY-PERIODIC-PAYBACK-BONUS',
  BiWeeklyPeriodicPaybackBonusAmount: 'BI-WEEKLY-PERIODIC-PAYBACK-BONUS',
  WeeklyPeriodicPaybackBonusAmount: 'WEEKLY-PERIODIC-PAYBACK-BONUS',
  MonthlyPeriodicPaybackBonusAmount: 'MONTHLY-PERIODIC-PAYBACK-BONUS'
}
export const ROLLING_CONSTANT = {
  depositRolling: 'Deposit Rolling',
  promotionCashRolling: 'Promotion Cash Rolling',
  bonusRolling: 'Bonus Rolling',
  vouchersRolling: 'Vouchers Rolling',
  giftCardsRolling: 'Gift Cards Rolling',
  seasonsRolling: 'Seasons Rolling'
}
export const ROLLING_TRANSLATION_KEY = {
  'Deposit Rolling': 'depositRolling',
  'Promotion Cash Rolling': 'promotionCashRolling',
  'Bonus Rolling': 'bonusRolling',
  'Vouchers Rolling': 'vouchersRolling',
  'Gift Cards Rolling': 'giftCardsRolling',
  'Seasons Rolling': 'seasonsRolling'
}

export const KRW_SYMBOL = '₩'
export const bankNameOptions = {
  EN: [
    { value: 'Chase Bank', label: 'Chase Bank' },
    { value: 'Wells Fargo', label: 'Wells Fargo' },
    { value: 'Bank of America', label: 'Bank of America' },
    { value: 'Citibank', label: 'Citibank' },
    { value: 'HSBC', label: 'HSBC' },
    { value: 'Barclays', label: 'Barclays' },
    { value: 'Santander', label: 'Santander' },
    { value: 'UBS', label: 'UBS' },
    { value: 'BNP Paribas', label: 'BNP Paribas' },
    { value: 'Deutsche Bank', label: 'Deutsche Bank' },
    { value: 'Industrial and Commercial Bank of China', label: 'Industrial and Commercial Bank of China' },
    { value: 'Kookmin Bank', label: 'Kookmin Bank' },
    { value: 'Nonghyup Bank', label: 'Nonghyup Bank' },
    { value: 'Woori Bank', label: 'Woori Bank' },
    { value: 'Saemaeul Bank', label: 'Saemaeul Bank' },
    { value: 'Shinhan Bank', label: 'Shinhan Bank' },
    { value: 'K Bank', label: 'K Bank' },
    { value: 'Kakao Bank', label: 'Kakao Bank' },
    { value: 'Hana Bank', label: 'Hana Bank' },
    { value: 'Post Office Bank', label: 'Post Office Bank' },
    { value: 'National Federation of Fisheries Cooperatives', label: 'National Federation of Fisheries Cooperatives' },
    { value: 'Standard Chartered Bank Korea', label: 'Standard Chartered Bank Korea' },
    { value: 'Kyongnam Bank', label: 'Kyongnam Bank' },
    { value: 'Kwangju Bank', label: 'Kwangju Bank' },
    { value: 'Shinhan Financial Investment', label: 'Shinhan Financial Investment' },
    { value: 'Industrial Bank of Korea', label: 'Industrial Bank of Korea' },
    { value: 'Nonghyup Members Cooperative', label: 'Nonghyup Members Cooperative' },
    { value: 'Daegu Bank', label: 'Daegu Bank' },
    { value: 'Bank of America', label: 'Bank of America' },
    { value: 'Busan Bank', label: 'Busan Bank' },
    { value: 'Korean Forestry Cooperatives Federation', label: 'Korean Forestry Cooperatives Federation' },
    { value: 'Industrial Bank of Korea', label: 'Industrial Bank of Korea' },
    { value: 'Mutual Savings Bank', label: 'Mutual Savings Bank' },
    { value: 'Export-Import Bank of Korea', label: 'Export-Import Bank of Korea' },
    { value: 'Korea Exchange Bank', label: 'Korea Exchange Bank' },
    { value: 'Jeonbuk Bank', label: 'Jeonbuk Bank' },
    { value: 'Jeju Bank', label: 'Jeju Bank' },
    { value: 'Citibank Korea', label: 'Citibank Korea' },
    { value: 'Hongkong and Shanghai Banking Corporation', label: 'Hongkong and Shanghai Banking Corporation' },
    { value: 'Other', label: 'Other' }
  ],
  KO: [
    { value: '체이스 은행', label: '체이스 은행' },
    { value: '웰스파고', label: '웰스파고' },
    { value: '뱅크 오브 아메리카', label: '뱅크 오브 아메리카' },
    { value: '시티은행', label: '시티은행' },
    { value: '홍콩상하이은행', label: '홍콩상하이은행' },
    { value: '바클레이스', label: '바클레이스' },
    { value: '산탄데르', label: '산탄데르' },
    { value: 'UBS', label: 'UBS' },
    { value: 'BNP 파리바', label: 'BNP 파리바' },
    { value: '독일은행', label: '독일은행' },
    { value: '중국공상은행', label: '중국공상은행' },
    { value: '국민은행', label: '국민은행' },
    { value: '농협은행', label: '농협은행' },
    { value: '우리은행', label: '우리은행' },
    { value: '새마을금고', label: '새마을금고' },
    { value: '신한은행', label: '신한은행' },
    { value: '케이뱅크', label: '케이뱅크' },
    { value: '카카오뱅크', label: '카카오뱅크' },
    { value: '하나은행', label: '하나은행' },
    { value: '우체국은행', label: '우체국은행' },
    { value: '신협중앙회', label: '신협중앙회' },
    { value: 'SC제일은행', label: 'SC제일은행' },
    { value: '경남은행', label: '경남은행' },
    { value: '광주은행', label: '광주은행' },
    { value: '신한금융투자', label: '신한금융투자' },
    { value: '기업은행', label: '기업은행' },
    { value: '수협입은행', label: '수협입은행' },
    { value: '농협회원조합', label: '농협회원조합' },
    { value: '대구은행', label: '대구은행' },
    { value: '뱅크오브아메리카', label: '뱅크오브아메리카' },
    { value: '부산은행', label: '부산은행' },
    { value: '산림조합중앙회', label: '산림조합중앙회' },
    { value: '산업은행', label: '산업은행' },
    { value: '상호신용금고', label: '상호신용금고' },
    { value: '수출입은행', label: '수출입은행' },
    { value: '외환은행', label: '외환은행' },
    { value: '전북은행', label: '전북은행' },
    { value: '제주은행', label: '제주은행' },
    { value: '한국씨티은행', label: '한국씨티은행' },
    { value: '홍콩상하이은행', label: '홍콩상하이은행' },
    { value: '기타', label: '기타' }
  ],
  JA: [
    { value: 'チェイス銀行', label: 'チェイス銀行' },
    { value: 'ウェルズ・ファーゴ', label: 'ウェルズ・ファーゴ' },
    { value: 'バンク・オブ・アメリカ', label: 'バンク・オブ・アメリカ' },
    { value: 'シティバンク', label: 'シティバンク' },
    { value: 'HSBC', label: 'HSBC' },
    { value: 'バークレイズ', label: 'バークレイズ' },
    { value: 'サンタンデール', label: 'サンタンデール' },
    { value: 'UBS', label: 'UBS' },
    { value: 'BNPパリバ', label: 'BNPパリバ' },
    { value: 'ドイツ銀行', label: 'ドイツ銀行' },
    { value: '中国工商銀行', label: '中国工商銀行' },
    { value: '国民銀行', label: '国民銀行' },
    { value: '農協銀行', label: '農協銀行' },
    { value: 'ウリ銀行', label: 'ウリ銀行' },
    { value: '새마을금고', label: '새마을금고' },
    { value: '新韓銀行', label: '新韓銀行' },
    { value: 'K銀行', label: 'K銀行' },
    { value: 'ココアバンク', label: 'ココアバンク' },
    { value: '銀行業務', label: '銀行業務' },
    { value: '郵便局銀行', label: '郵便局銀行' },
    { value: '全国漁業協同組合連合会', label: '全国漁業協同組合連合会' },
    { value: 'SC제일은행', label: 'SC제일은행' },
    { value: '경남은행', label: '경남은행' },
    { value: '광주은행', label: '광주은행' },
    { value: '신한금융투자', label: '신한금융투자' },
    { value: '기업은행', label: '기업은행' },
    { value: '수협입은행', label: '수협입은행' },
    { value: '농협회원조합', label: '농협회원조합' },
    { value: '대구은행', label: '대구은행' },
    { value: '뱅크오브아메리카', label: '뱅크오브아메리카' },
    { value: '부산은행', label: '부산은행' },
    { value: '산림조합중앙회', label: '산림조합중앙회' },
    { value: '산업은행', label: '산업은행' },
    { value: '상호신용금고', label: '상호신용금고' },
    { value: '수출입은행', label: '수출입은행' },
    { value: '외환은행', label: '외환은행' },
    { value: '전북은행', label: '전북은행' },
    { value: '제주은행', label: '제주은행' },
    { value: '한국씨티은행', label: '한국씨티은행' },
    { value: '홍콩상하이은행', label: '홍콩상하이은행' },
    { value: 'その他', label: 'その他' }
  ]
}
