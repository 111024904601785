const ENJSON = {

  translation: {
    // casino
    habanero: 'Habanero',
    gamble: 'Gamble',
    poker: 'Poker',
    myBets: 'My Bets Casino',
    casino: 'Casino',
    scratchcard: 'Scratchcard',
    slot: 'Slot',
    blackjack: 'BlackJack',
    'live games': 'Live Casino',
    baccarat: 'Baccarat',
    hot: 'Hot',
    new: 'New',
    roulette: 'Roulette',
    unknown: 'Unknown',
    pragmatic: 'Pragmatic Play',
    evolution: 'Evolution',
    pgsoft: 'PG Soft',
    relax: 'Relax',
    extendednetent: 'Extended Netent',
    MG: 'MG',
    BetterLive: 'BetterLive',
    PopOk: 'PopOk',
    SAGaming: 'SAGaming',
    CreedRomz: 'CreedRomz',
    Bgaming: 'Bgaming',
    Winfinity: 'Winfinity',
    ImagineLive: 'ImagineLive',
    ezugi: 'Ezugi',
    AWC: 'AWC',
    hacksaw: 'Hacksaw',
    redTiger: 'Red Tiger',
    netEnt: 'NetEnt',
    bigTimeGaming: 'Big Time Gaming',
    nolimitCity: 'Nolimit City',
    wagered: 'Wagered',
    betAmount: 'Bet Amount',
    betSlip: 'Bet Slip',
    recommendGames: 'Recommend Games',
    noProvidersFound: 'No Providers Found',
    casinoBets: 'Casino Bets',
    fullScreen: 'Fullscreen',
    recommended: 'Recommended',
    enableTheatre: 'Enable theatre mode',
    disableTheatre: 'Disable theatre mode',
    viewAllProvider: 'View All Provider',
    games: 'Games',
    searchYourGame: 'Search Your Game',
    searchAtleast3Char: 'Search requires at least 2 characters.',
    providers: 'Providers',
    allBets: 'Total bet Leaderboard',
    raceLeaderBoard: 'Big win Leaderboard',
    raceRollingContestBoard: 'Rolling Contest leader board',
    newLevelUnlock: 'New Level Unlock',
    newSeasonUnlock: 'New Season Unlock',
    vouchersAndGiftcards: 'Vouchers and Gift Cards',
    giftCards: 'Gift Cards',
    availableRedeemptions: 'Available Redeemptions',
    noGiftCardFound: 'No gift cards available',
    joinContest: 'Join Contest',
    contestName: 'Contest Name',
    joincontestContent: 'Bet your wits, win your fate – enter if you dare!',
    errorWhileLoadingGame: 'Error while loading Game',
    season: 'Season',
    betPlaced: 'Bet Placed',
    inPlay: 'In Play',
    dinoCasinoLeaderboard: 'Dino Casino Leaderboard',
    welcomeToDinoCasino: 'Welcome to Dino Casino!',
    dayFilterName: 'D',
    weekFilterName: 'W',
    monthFilterName: 'M',

    // common
    myLevelProgress: 'My Level Progress',
    levelStatus: 'Level Status',
    remaining: 'Remaining',
    referral: 'Referral',
    balance: 'Balance',
    pageNotFound: 'Page Not Found',
    noGames: 'No games found',
    oopps: 'Oopps',
    somethingNotWorking: 'We’re sorry, something is not working here!',
    noCategory: 'No category found',
    liveSupport: 'Live Support',
    rollingContest: 'Rolling Contest',
    event: 'Event',
    bigWin: 'Big Win',
    sorryForInconvenience: 'Sorry for the inconveniences.',
    maintenaceModeOn: 'Maintenance mode is on',
    maintenaceContent: 'Site will be available soon. Thank you for your patience',
    noDataFound: 'No Data Found',
    activateSuccess: 'Activated Successfully',
    pauseSuccess: 'Paused Successfully',
    redeemSuccess: 'Redeem Successfully',
    unlocked: 'Unlocked',
    activate: 'Activate',
    pause: 'Pause',
    refresh: 'Refresh',
    somethingWentWrong: 'Something went wrong. Please reload',
    yes: 'Yes',
    no: 'No',
    redeem: 'Redeem',
    validFrom: 'Valid From',
    validTo: 'Valid To',
    betTarget: 'Bet Target',
    search: 'Search',
    payout: 'Payout',
    loadMore: 'Load More',
    sortBy: 'Sort By',
    filterBy: 'Filter By',
    atoz: 'A - Z',
    ztoa: 'Z - A',
    all: 'All',
    viewAll: 'View All',
    save: 'Save',
    of: 'of',
    game: 'Game',
    user: 'User',
    time: 'Time',
    press: 'Press',
    transactions: 'Transactions',
    claimed: 'Claimed',
    from: 'From',
    to: 'To',
    selectDate: 'Select Date',
    total: 'Total',
    LOST: 'LOST',
    BET: 'BET',
    WIN: 'WIN',
    submit: 'Submit',
    Active: 'Active',
    InActive: 'InActive',
    date: 'Date',
    rank: 'Rank',
    username: 'Username',
    createdAt: 'Created At',
    status: 'Status',
    copy: 'Copy',
    cancel: 'Cancel',
    confirm: 'Confirm',
    copied: 'Copied',
    copiedSuccess: 'Copied successfully',
    home: 'Home',
    theme: 'Theme',
    light: 'Light',
    dark: 'Dark',
    LIVE: 'LIVE',
    LOSE: 'LOSE',
    playing: 'playing',
    APPROVED: 'Approved',
    REJECTED: 'Rejected',
    action: 'Action',
    others: 'Others',
    loading: 'Loading...',
    submitting: 'Submitting...',
    hide: 'Hide',
    request: 'Requests',
    clearAll: 'Clear All',
    record: 'Records',
    data: 'Data',
    na: 'N/A',
    ACCEPT: 'Accepted',
    PENDING: 'Pending',
    REJECT: 'Rejected',
    CANCEL: 'Cancelled',
    withdrawal: 'Withdrawal',
    prize: 'Prize',
    noDeposit: 'No Deposits',
    noWithdrawl: 'No Withdrawal',
    success: 'Success',
    error: 'Error',
    warning: 'Warning',
    hidden: 'Hidden',
    show: 'Show',
    amount: 'Amount',
    language: 'Language',
    areYouSure: 'Are You Sure',
    displaying: 'Displaying',
    comingSoon: 'Coming Soon',
    goToLobby: 'Go To Lobby',
    level: 'Level',
    days: 'days',
    hours: 'hours',
    minutes: 'minutes',
    seconds: 'seconds',
    dinoCasino: 'Dino Casino,',
    majorHiddenProgress: 'major hidden update in progress.',
    experienceSoon: 'You’ll be able to experience it soon!',

    // footer
    responsibleGaming: 'Responsible Gambling',
    otherLinks: 'Other Links',
    landingPage: 'Landing Page',
    supportLinks: 'Support Links',

    // cms
    termsAndConditon: 'Terms and Condition',

    // notification
    cashAmount: 'Cash Amount',
    EMERGENCY: 'Emergency',
    MAINTENANCE: 'Maintenance',
    Bonus: 'Bonus',
    WALLET: 'Wallet',
    noNotificationFound: 'No Notifications Available',
    notificationTitle: 'Notifications',
    notificationRemovedSuccessfully: 'Notification Removed Successfully',
    showUnRead: 'Show Unread',
    markAll: 'Mark all read',
    deleteAll: 'Delete all',

    // Bonus ticket
    noBonusComingSoon: 'Coming Soon, Not started yet',
    'UNIQUE-NUMBER-BONUS': 'Unique Number Bonus',
    noBonusFound: 'No bonus found',
    bonusTickets: 'Bonus Tickets',
    couponNumber: 'Coupon Number',
    bonus: 'Bonus',
    hotTimeBonus: 'Hot Time Bonus',
    compensationBonus: 'Compensation Bonus',
    reocurringBonus: 'Reocurring Bonus',
    reoccuringBonus: 'Reoccuring bonus',
    firstDepositBonus: 'First Deposit Bonus',
    noBonus: 'No bonus',
    bonusLapsed: 'Bonus Lapsed',
    'HOT-TIME-BONUS': 'HOT-TIME-BONUS',
    'COMPENSATION-BONUS': 'COMPENSATION-BONUS',
    'RE-OCCURING-DEPOSIT-BONUS': 'RE-OCCURING-DEPOSIT-BONUS',
    'FIRST-DEPOSIT-BONUS': 'FIRST-DEPOSIT-BONUS',
    'UNIQUE-TICKET-BONUS': 'UNIQUE-TICKET-BONUS',
    notActive: 'Not Active',
    viewProgress: 'View Progress',
    paybackBonus: 'Payback Bonus',
    'PAYBACK-BONUS': 'PAYBACK-BONUS',
    redeemAbleCoupons: 'Redeemable Coupons',
    nonApplicableCoupons: 'Non-Applicable Coupons',
    bonusDetail: 'Bonus Details',
    addOnOddBonus: 'Add On OddBonus',
    bonusPercentage: 'Bonus Percentage',
    bonusType: 'Bonus Type',
    maxBonusLimit: 'Maximum Bonus Limit',
    'WINNING-BONUS': 'WINNING-BONUS',
    'BETTING-BONUS': 'BETTING-BONUS',
    'MIXED-MATCH': 'MIXED-MATCH',
    'BET-COMPENSATION': 'BET-COMPENSATION',
    rackbackBonus: 'RackBack Bonus',
    periodicBonus: 'Periodic Payback',
    generalBonus: 'General Bonus',
    rackbackBonusEarned: 'Rackback Earned',
    periodicBonusEarned: 'Payback Earned',
    generalBonusEarned: 'Bonus Earned',
    claimedAmount: 'Claimed Amount',
    details: 'Details',
    availableRackbackEarning: 'Available Rakeback Earnings',
    endsIn: 'Ends in',
    available: 'Available',
    Min: 'Min',
    Max: 'Max',
    availableIn: 'Available soon in',
    claimSuccess: 'Claimed Successfully',
    dailyBonus: 'Daily Bonus',
    weeklyBonus: 'Weekly Bonus',
    biWeeklyBonus: 'Bi-weekly Bonus',
    monthlyBonus: 'Monthly Bonus',

    // seting
    forgotPassword: 'Forgot Password',
    setting: 'Setting',
    changePassword: 'Change Password',
    login: 'LogIn',
    profile: 'Profile',
    transactionDeposit: 'Deposit',
    transactionWithdrawal: 'Withdrawal',
    referredUsers: 'Referred Users',
    referAUser: 'Refer a User',
    referByLink: 'Refer a user by link',
    noReferralUsers: 'No Referred Users Found',
    promotionCash: 'Promotion Cash',
    checkKycStatus: 'KYC Status',
    kycStatusTitle: 'KYC STATUS',
    verified: 'Verified',
    notVerified: 'Not Verified',
    insufficientBalance: 'Insufficient Balance !',
    depositableAmount: 'Depositable amount',
    withdrawableAmount: 'Withdrawable amount',
    depositWallet: 'Deposit',
    withdrawalWallet: 'Withdraw',
    wallet: 'Wallet',
    deposit: 'Deposit',
    withdraw: 'Withdraw',
    vaultAmount: 'Vault Amount',
    vault: 'Vault',
    vaultDepositSuccess: 'Deposit Successfully',
    vaultWithdrawSuccess: 'Withdraw Successfully ',
    verifyUser: 'Verify User',
    requestCreatedSuccess: 'Request Created Successfully',
    walletActionSourceAlreadyExists: 'Pending Request Already Exists',
    requestWithdraw: 'Request Withdraw',
    requestDeposit: 'Request Deposit',
    mustBeMultipleOf10000: 'The amount must be a multiple of 10,000.',
    min10000Required: 'The amount must be greater than or equal to 10,000',
    maxAmountEnteredis: 'Maximum amount is',
    requestErrorMessage: 'request amount must be less than or equal to your wallet balance',
    mustBeInteger: 'Decimal value not allowed',
    applyCoupon: 'Apply coupon',
    availThisCoupon: 'Avail this coupon by depositing',
    orAbove: 'or above',
    andGetBenefit: 'and get benefit of',
    back: 'Back',
    selectCouponCode: 'Select coupon',
    couponIsApplied: 'Coupon is applied',
    coupons: 'Coupons',
    lockedFunds: 'Locked funds',

    // favourite games and play game
    favourites: 'Favourites',
    noFavouritesGames: 'No games added to your favourite list.',
    addToFavourites: 'Add to favourites',
    removeFromFavourites: 'Remove from favourites',
    addToFavSuccess: 'Added to favourites successfully',
    removeFromFavSuccess: 'Removed from favourites successfully',

    // Sign up  and login form
    tenanatAreBlocked: 'Tenant are blocked',
    blacklistedError: 'You are blacklisted, Not allowed to login',
    kycNotComplete: 'Your KYC is not completed, Please verify first to access the platform',
    logoutSuccessfull: 'You have been logged out successfully',
    sessionExpired: 'Your session has expired!',
    nicknameRequired: 'Nickname is required.',
    nickNameLabel: 'Nickname',
    nickNamePlaceholder: 'Enter Nickname',
    referralCodeRequired: 'Referral Code Required',
    referralCodePlaceholder: 'Enter a referral code',
    oldPasswordDidNotMatch: "Old password doesn't match",
    withdrawlPasswordDoesNotMatch: 'Withdrawl Password Does Not Match',
    currencyRequired: 'Currency is Required',
    amountRequired: 'Amount is Required',
    selectCurrency: 'Select Currency',
    currency: 'Currency',
    enterAmountPlaceholder: 'Enter amount',
    acceptTerms: 'I accept the terms and conditions',
    usernameLabel: 'Username',
    passwordLabel: 'Password',
    oldPasswordLabel: 'Old Password',
    newPasswordLabel: 'New Password',
    confirmPasswordLabel: 'Confirm Password',
    confirmNewPasswordLabel: 'Confirm New Password',
    idOfUserLabel: 'ID of User',
    legalNameLabel: 'Legal Name',
    nationalityLabel: 'Nationality',
    dateOfBirthLabel: 'Date of Birth',
    selectMonth: 'Select Month',
    selectDay: 'Select Day',
    selectYear: 'Select Year',
    phoneLabel: 'Phone',
    preferredCurrencyLabel: 'Preferred Currency',
    bankNameLabel: 'Bank Name',
    accountNumberLabel: 'Account Number',
    withdrawalPasswordLabel: 'Withdrawal Password',
    reEnterwithdrawalPasswordLabel: 'Re-enter Withdrawal password',
    signIn: 'Sign In',
    signUp: 'Sign Up',
    userIdPlaceholder: 'Enter user Id',
    passwordRequired: 'Password Required',
    oldPasswordRequired: 'Old Password Required',
    newPasswordRequired: 'New Password Required',
    accountFrozen: 'Your account is frozen till',
    userLoggedOut: 'Logged Out Successfully',
    loggingIn: 'Submitting please wait...',
    selectNationality: 'Select Nationality',
    selectBankName: 'Select Bank Name',
    selectPreferredCurrency: 'Select Preferred Currency',
    dontHaveAccount: 'Don’t have an account?',
    registerAnAccount: 'Register an Account',
    alreadyHaveAnAccount: 'Already have an account?',
    fillAllFields: 'Please fill out all required fields before signing up.',
    userNamePlaceholder: 'Enter Username',
    min8Numbers: 'Min 8 numbers required',
    passwordValidationMessage: 'Password must have at least 8 characters with at least one uppercase letter, one number and one special character. Spaces, dots and comma not allowed',
    withdrawlPasswordValidationMessage: 'Withdrawal password must have at least 8 characters with at least one uppercase letter, one number and one special character. Spaces, dots and comma not allowed',
    passwordPlaceholder: 'Enter Password',
    confirmPasswordPlaceholder: 'Enter Confirm Password',
    passwordWithdrawalPlaceholder: 'Enter Withdrawal Password',
    oldPasswordPlaceholder: 'Enter Old Password',
    newPasswordPlaceholder: 'Enter New Password',
    confirmNewPasswordPlaceholder: 'Enter Confirm New Password',
    confirmPasswordWithdrawalPlaceholder: 'Re Enter Withdrawal Password',
    legalNamePlaceholder: 'Enter Legal Name',
    phonePlaceholder: 'Enter Phone Number',
    searchPhonePlaceholder: 'Search Country',
    accountNumberPlaceholder: 'Enter Account Number',
    userIdRequired: 'User Id Required',
    userIdInvalid: 'Only alphanumeric is accepted',
    confirmNewPasswordRequired: 'Confirm New Password Required',
    confirmPasswordRequired: 'Confirm Password Required',
    passwordWithdrawalRequired: 'Withdrawal Password Required',
    confirmPasswordWithdrawalRequired: 'Confirm Withdrawal Password Required',
    legalNameRequired: 'Legal Name Required',
    phoneRequired: 'Phone Required',
    dateOfBirthRequired: 'Date Of Birth Required',
    userNameRequired: 'User Name Required',
    requiredAccountNumber: 'Account Number Required',
    countryRequired: 'Country Required',
    matchPassword: 'Password must match',
    matchWithdrawalPassword: 'Withdrawal Password must match',
    onlyAlphabet: 'Only Alphabets allowed',
    mustBe18: 'You Must be 18 Year old to get registered',
    validDOB: 'Please enter a valid Date of Birth',
    max20Characters: 'Maximum 20 Characters Allowed',
    min3Characters: 'Minimum 3 Characters Required',
    min2Characters: 'Minimum 2 Characters Required',
    min4Characters: 'Minimum 4 Characters Required',
    min5Numbers: 'Minimum 5 digits required including dial code',
    spaceNotAllowed: 'Spaces, dots and comma not allowed',
    onlySpaceNotAllowed: 'Spaces not allowed',
    max100Characters: 'Maximum 100 Characters Allowed',
    max50Characters: 'Maximum 50 Characters Allowed',
    max30Characters: 'Maximum 30 Characters Allowed',
    max16Characters: 'Maximum 16 Characters Allowed',
    max16Numbers: 'Maximum 16 digits allowed including dial code',
    preferredLanguageRequired: 'Preferred Language Required',
    preferredCurrencyRequired: 'Preferred Currency Required',
    bankNameRequired: 'Bank Name Required',
    nationalityRequired: 'Nationality Required',
    legalName: 'Alphabets and spaces allowed only',
    legalNameOneSpace: 'Only one spaces allowed between words',
    selectPreferredLanguage: 'Select Preferred Language',
    enterValidPhone: 'Please enter valid phone number',
    userNameAllowed: 'Lowercase alphabets allowed and no spaces allowed.',
    referralCodeInvalid: 'Invalid referral code',
    userNameAlreadyExists: 'Username already exists!',
    incorrectCredentials: 'Username with given password does not exist!',
    UserCountryCodeWithPhoneAlreadyExists: 'Username with this phone already exists!',
    loginErrorMessage: 'Please Login to Continue',
    nickNamAlreadyExists: 'Nickname already exists.',
    logout: 'Logout',
    logoutContent: 'Don’t forget to check out our huge selection of casino and sports promotions before you leave!',
    setNewPass: 'Set a new password',
    loginSuccess: 'Login Successful',
    signupSuccess: 'Sign Up Successful',
    updatedSuccess: 'Updated Successfully',
    forgetPasswordRequest: 'Forget Password?',
    forgetPassword: 'Forget Password',
    forgetPasswordRequestSent: 'Forget Password Request Sent!!',
    itWillbeYourUpdatedWithdrawalPasswordAsWell: 'Please note that this will also serve as your updated withdrawal password.',
    registerYourAccount: 'Register your account!',
    isAnonymous: 'Mark as anonymous',
    markKnown: 'Mark as Known',
    alreadyLogin: 'Already Logged in',
    alreadyLoginMessage: 'User is already Logged with many ips due you want to login here?',

    // voucher
    myVouchers: 'My Vouchers',
    noVoucherFound: 'No vouchers available',
    voucherName: 'Voucher Name',
    voucherUseLimit: 'Voucher Use Limit',
    vouchers: 'Vouchers',
    remainingRedeemptions: 'Remaining Redeemptions',

    // Cookie Consent
    cookieTitle: 'Cookies!',
    declineButton: 'Decline',
    acceptButton: 'Accept',
    cookieDeclinedMessage: 'For the best possible experience, cookies must be accepted to continue using this website. Please accept the cookies if you want to proceed.',
    cookieDescription: 'Welcome to dino.com! We use cookies to optimise your user experience. By clicking \'Accept,\' you agree to their use. You can opt-out by clicking \'Decline.',
    registeredForRollingContest: "Locked and loaded for the games! You're officially registered for the excitement!",
    alreadyRegister: 'You are already registered!!',
    noContestSchedule: 'No Contest Scheduled',
    playerName: 'Player Name',
    leadAmount: 'Lead Amount',

    // ********** SportsBook Translations ********************
    topMatch: 'Top Match',
    eventStartIn: 'Event Starts In',
    betNotPlacedPleaseTryAgain: 'Bet can not be place for this event, Please try again',
    topMatchesNotAvailable: 'Top Matches Not Available',
    goToEvent: 'Go to Event',
    hotCombo: 'Hot Combos',
    liveMatchResult: 'Live Match Result',
    processing: 'Processing',
    favoriteRemoved: 'Favorite removed successfully',
    favoriteAdded: 'Favorite added successfully',
    maxCustomMarketLimit: 'You can select max 10 custom market conbination in single bet slip',
    betErrorHotCombo: 'Bet can not be place for this hot combo, try with different hot combo',
    cashoutMessage: 'Cashout inprogress, Please check notification for more info',
    cashoutInprogress: 'Cashout Inprogress',
    outcomeSuspended: 'Outcome is suspended',
    outcomeCancelled: 'Outcome is cancelled',
    noEvents: 'Matches not Available',
    noSportsAvailable: 'No sports available',
    notStarted: 'Match is not started yet.',
    matchLive: 'Match is live',
    matchSuspended: 'Match is suspended',
    ended: 'Match is finished',
    matchClosed: 'Match is closed',
    marketActive: 'Market is active',
    marketDeactive: 'Market is deactivated',
    marketSuspended: 'Market is suspended',
    marketSettled: 'Market is settled',
    marketCancelled: 'Market is cancelled',
    outcomeDeactive: 'Outcome is deactivated',
    outcomeActive: 'Outcome is active',
    outcomeSettled: 'Outcome is settled',
    sportsBook: 'Sports Book',
    sportBets: 'Sports Bets',
    half_won: 'Half Won',
    half_lost: 'Half Lost',
    odds: 'Odds',
    sports: 'Sports',
    eSports: 'E Sports',
    mySportsBets: 'My Bets Sportsbook',
    noHotComboAvailable: 'Hot Combos are not available at this moment',
    pleaseEnterStake: 'Please enter a stake',
    single: 'Single',
    combo: 'Combo',
    noBetsAdded: 'No bets added',
    possibleWin: 'Possible Win',
    placeBet: 'Place Bet',
    clearBetslip: 'Clear Betslip',
    vs: 'VS',
    liveEvents: 'Live Events',
    topEvents: 'Top Events',
    upcomingEvents: 'Upcoming Events',
    highlight: 'Highlight',
    schedule: 'Schedule',
    live: 'Live',
    favorite: 'Favorite',
    myBet: 'My Bet',
    result: 'Result',
    prev: 'Prev',
    next: 'Next',
    today: 'Today',
    tomorrow: 'Tomorrow',
    favorites: 'Favorites',
    selectSport: 'Select Sport',
    selectCountry: 'Select Country',
    selectLeague: 'Select League',
    fromDate: 'From Date',
    toDate: 'To Date',
    winAmount: 'Win Amount',
    stake: 'Stake',
    lostAmount: 'Loss Amount',
    cashoutAmount: 'Cashout Amount',
    pending: 'Pending',
    rejected: 'Rejected',
    cashoutButton: 'Cashout',
    betSlipId: 'Betslip ID',
    in_game: 'In Game',
    cashout: 'Cash Out',
    won: 'Won',
    lost: 'Lost',
    refund: 'Refunded',
    partial_won: 'Partial Won',
    topLeague: 'Top Leagues',
    noLeagues: 'Leagues not Available',
    alternativeOddType: 'Alternative Odd Type',
    areYouSureCashout: 'Are you sure you want to cashout?',
    alternateStakeButton: 'Alternative Stake',
    reOfferButton: 'Bet Reoffer',
    alternativeStakeTitle: 'Alternative Stake Offer',
    viewAllMarket: 'Click here to view all market',
    closed: 'Closed',
    removeClosedBet: 'Please remove closed bet form betslip!',
    betSetting: 'Odds Setting',
    any: 'Accept any odds changes',
    none: "Don't accept odds changes",
    higher: 'Accept higher odds changes',
    reset: 'Reset',
    selectFromDate: 'Select From Date',
    selectToDate: 'Select To Date',
    customBet: 'Custom Bet',
    calclutedOds: 'Calculated Odd',
    removeMainBet: 'Please remove main bets first from betslip',
    removeCustomBet: 'Please remove custom bets first from betslip',
    addSameCusomMarket: 'Please add same custom market events',
    enterStake: 'Please enter stake',
    main: 'Main',
    custom: 'Custom',
    quickBet: 'Quick Bet',
    quickBetDesc: 'QuickBet mode is on! After single click on any selection, it will place your bet immediately. See all your bets on',
    page: 'page',
    quickBetIsActiveWith: 'Quick Bet is active with',
    pleaseEnterStakeToActiveBet: 'Please enter stake to activate the bet',
    loginToPlaceBet: 'Please login to place bet',
    tie: 'Tie',
    addAtleasetTwoEvents: 'Add minimum two custom events to place bet',
    events: 'Events',
    noEventsSchedule: 'No events scheduled',
    betPlacedSuccess: 'Bet is being processed, Please check the notification for more info',

    // new translations

    // sports
    betBySports: 'BetBy Sports',
    sportsBonus: 'Sports Bonus',
    deleteNotificationSucces: 'Notification deleted successfully',
    markAnonymous: 'You have been marked as Anonymous',
    removeAnonymous: 'You have been marked as Unanonymous',

    // bonus
    activatedFrom: 'Activated From',
    depositRolling: 'Deposit Rolling',
    promotionCashRolling: 'Promotion Cash Rolling',
    bonusRolling: 'Bonus Rolling',
    rollOver: 'Rollover',
    rollOverType: 'Rollover type',
    allType: 'All type',
    allStatus: 'All status',
    complete: 'Complete',
    inprogress: 'In progress',
    notStartedRollStatus: 'Not started',
    pleaseSelectRollType: 'Please select roll type',
    totalClaimed: 'Total claimed',
    overview: 'Overview',
    'RACKBACK-BONUS': 'RACKBACK-BONUS',
    'DAILY-PERIODIC-PAYBACK-BONUS': 'DAILY-PERIODIC-PAYBACK-BONUS',
    'BI-WEEKLY-PERIODIC-PAYBACK-BONUS': 'BI-WEEKLY-PERIODIC-PAYBACK-BONUS',
    'WEEKLY-PERIODIC-PAYBACK-BONUS': 'WEEKLY-PERIODIC-PAYBACK-BONUS',
    'MONTHLY-PERIODIC-PAYBACK-BONUS': 'MONTHLY-PERIODIC-PAYBACK-BONUS',
    claimAfter: 'Claim after',
    dailyLimitReached: 'Daily limit reached',
    weeklyLimitReached: 'Weekly limit reached',
    monthlyLimitReached: 'Monthly limit reached',
    compensationTargetNotMet: 'Compensation target not met',
    bonusLimitReached: 'Bonus limit reached',
    claim: 'Claim',
    sportsPaybackBonus: 'Sports Payback Bonus',

    // common
    player_: 'player_',

    vouchersRolling: 'Vouchers Rolling',
    giftCardsRolling: 'Gift Cards Rolling',
    seasonsRolling: 'Seasons Rolling',
    promotions: 'Promotions',
    achievements: 'Achievements'

  }
}

export default ENJSON
