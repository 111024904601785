import { spawn } from 'redux-saga/effects'

import loginWatcher from './login'
import casinoMenuWatcher from './casinoMenu'
import transactionsWatcher from './transactions'
import languageWatcher from './language'
import userWatcher from './user'
import betsWatcher from './bets'
import cmsWatcher from './cms'
import sportsWatcher from './sports'
import voucherWatcher from './voucher'
import bonusWatcher from './bonus'

export default function * rootSaga () {
  yield spawn(loginWatcher)
  yield spawn(userWatcher)
  yield spawn(casinoMenuWatcher)
  yield spawn(transactionsWatcher)
  yield spawn(languageWatcher)
  yield spawn(betsWatcher)
  yield spawn(cmsWatcher)
  yield spawn(sportsWatcher)
  yield spawn(bonusWatcher)
  yield spawn(voucherWatcher)
}
