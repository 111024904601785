import React from 'react'
import './loadmore.scss'
import { useTranslation } from 'react-i18next'
const LoadMore = ({ totalCount, renderCount, loadMoreData, show, isShowText = true }) => {
  const { t } = useTranslation()
  return (
    <>

      {totalCount > 0 && (
        <>
          <div className='text-center m-auto loadmore '>

            {isShowText && (
              <>
                <div className='progress mt-5' style={{ height: '2px' }}>
                  <div className='progress-bar' role='progressbar' style={{ width: `${(renderCount() / totalCount * 100)}%` }} aria-valuenow='25' aria-valuemin='0' aria-valuemax='100' />
                </div>
                <p className='text-muted pt-2'>
                  {t('displaying')} {renderCount()} {t('of')} {totalCount} {t(show || 'games')}
                </p>
              </>
            )}
          </div>
          {renderCount() !== totalCount &&
            <div className='load-more-btn text-center'>
              <button onClick={loadMoreData}>
                {t('loadMore')}
              </button>
            </div>}
        </>
      )}
    </>
  )
}

export default LoadMore
