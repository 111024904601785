import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import { REACT_ICONS } from '../../utils/icons'

export const IdleModalogout = ({ isOpen, handleCloseModal }) => {
  const { t } = useTranslation()

  return (
    <>
      <Modal className='' size='md' show={isOpen} centered scrollable>
        <Modal.Header className='d-flex justify-content-end align-items-center'>
          {/* <div onClick={handleCloseModal} role='button'><img style={{ width: 24, position: 'relative', zIndex: 10 }} src='/icon/close.svg' alt='' /></div> */}
        </Modal.Header>
        <Modal.Body>
          <div className='session-logout-success'>
            <div className='timer-icon mb-4'>
              {REACT_ICONS.timer}
            </div>
            <h4>{t('sessionExpired')}</h4>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
