const KOJSON = {
  translation: {
    // casino
    habanero: '하바네로',
    gamble: '겜블',
    poker: '포커',
    myBets: '배팅 내역',
    casino: '카지노',
    scratchcard: '스크래치카드',
    slot: '슬롯',
    blackjack: '블랙잭',
    'live games': '라이브 카지노',
    baccarat: '바카라',
    hot: '인기 게임',
    new: '신규 게임',
    roulette: '룰렛',
    unknown: '알 수 없음',
    pragmatic: '프라그마틱 플레이',
    evolution: '에볼루션',
    pgsoft: 'PG 소프트',
    relax: '릴렉스 게이밍',
    extendednetent: 'EX 넷엔트',
    MG: 'MG',
    BetterLive: '베터 라이브',
    PopOk: 'POP OK',
    SAGaming: 'SA게이밍',
    CreedRomz: '크리드롬즈',
    Bgaming: 'B게이밍',
    Winfinity: '윈피니티',
    ImagineLive: '이매진라이브',
    ezugi: '에즈기',
    AWC: 'AWC',
    hacksaw: '헥소 게이밍',
    redTiger: '레드 타이거',
    netEnt: '넷엔트',
    bigTimeGaming: '빅 타임 게이밍',
    nolimitCity: '노리밋 시티',
    wagered: '베팅 진행중',
    betAmount: '베팅 금액',
    betSlip: '베팅 내역',
    recommendGames: '추천 게임',
    noProvidersFound: '현재 점검 중 입니다.',
    casinoBets: '실시간 베팅',
    fullScreen: '전체 화면',
    recommended: '추천 게임',
    enableTheatre: '영화관 모드 활성화',
    disableTheatre: '영화관 모드 비활성화',
    viewAllProvider: '전체 게임사 목록',
    games: '게임',
    searchYourGame: '게임 검색',
    searchAtleast3Char: '검색어는 최소 2자 이상이어야 합니다.',
    providers: '게임사',
    allBets: '실시간 베팅 현황',
    raceLeaderBoard: '명예의 전당',
    raceRollingContestBoard: '토너먼트 랭킹',
    newLevelUnlock: '새로운 레벨 잠금 해제',
    newSeasonUnlock: '새로운 시즌 잠금 해제',
    vouchersAndGiftcards: '쿠폰 및 기프트카드',
    giftCards: '기프트 카드',
    availableRedeemptions: '사용 가능한 교환 횟수',
    noGiftCardFound: '사용 가능한 기프트 카드가 없습니다',
    joinContest: '토너먼트 참가',
    contestName: '토너먼트',
    joincontestContent: '지혜를 걸고, 운명을 승리하라 – 도전할 용기가 있다면 참가하세요!',
    errorWhileLoadingGame: '게임 로딩 중 오류 발생',
    season: '시즌',
    betPlaced: '베팅 완료',
    inPlay: '인 플레이',
    dinoCasinoLeaderboard: '다이노 카지노 베팅 현황',
    welcomeToDinoCasino: '다이노 카지노에 오신 것을 환영합니다!',
    dayFilterName: '일간',
    weekFilterName: '주간',
    monthFilterName: '월간',

    // common
    myLevelProgress: '레벨 진행 상황',
    levelStatus: '레벨 현황',
    remaining: '잔여 금액',
    referral: '추천인 코드',
    balance: '잔액',
    pageNotFound: '요청한 페이지를 찾을 수 없습니다.',
    noGames: '게임을 찾을 수 없음',
    oopps: '죄송합니다.',
    somethingNotWorking: '고객센터로 문의 주시기 바랍니다.',
    noCategory: '검색결과가 없습니다.',
    liveSupport: '실시간 라이브 채팅',
    rollingContest: '진행중인 롤링대회',
    event: '이벤트',
    bigWin: '명예의 전당',
    sorryForInconvenience: '불편을 끼쳐드려 죄송합니다.',
    maintenaceModeOn: '시스템 점검중 입니다',
    maintenaceContent: '사이트 점검이 완료 될 예정입니다 기다려주셔서 대단히 감사드립니다',
    noDataFound: '데이터를 찾을 수 없음',
    activateSuccess: '활성화 되었습니다.',
    pauseSuccess: '일시 정지 되었습니다.',
    redeemSuccess: '사용되었습니다.',
    unlocked: '잠금 해제',
    activate: '활성화',
    pause: '일시 정지',
    refresh: '새로고침',
    somethingWentWrong: '문제가 발생했습니다. 새로고침 해주세요.',
    yes: '예',
    no: '아니오',
    redeem: '사용',
    validFrom: '유효 시작일',
    validTo: '유효 종료일',
    betTarget: '베팅 목표',
    search: '검색',
    payout: '당첨 금액',
    loadMore: '더 보기',
    sortBy: '정렬 기준',
    filterBy: '필터 기준',
    atoz: '가 - 하',
    ztoa: '하 - 가',
    all: '모두',
    viewAll: '모두 보기',
    save: '저장',
    of: '중',
    game: '게임',
    user: '사용자',
    time: '시간',
    press: '클릭',
    transactions: '거래 내역',
    claimed: '보상 완료',
    from: '부터',
    to: '까지',
    selectDate: '날짜 선택',
    total: '합계',
    LOST: '미적중',
    BET: '베팅',
    WIN: '적중',
    submit: '신청하기',
    Active: '활성화',
    InActive: '비활성화',
    date: '날짜',
    rank: '순위',
    username: '사용자 이름',
    createdAt: '생성 일시',
    status: '상태',
    copy: '복사',
    cancel: '취소',
    confirm: '확인',
    copied: '복사되었습니다.',
    copiedSuccess: '복사 완료 되었습니다.',
    home: '메인',
    theme: '테마',
    light: '밝게',
    dark: '어둡게',
    LIVE: '실시간',
    LOSE: '미적중',
    playing: '플레이',
    APPROVED: '승인',
    REJECTED: '거부',
    action: '결정',
    others: '다른 게임',
    loading: '로딩 중...',
    submitting: '제출 중...',
    hide: '숨기기',
    request: '요청',
    clearAll: '모두 지우기',
    record: '기록',
    data: '데이터',
    na: '해당 없음',
    ACCEPT: '수락',
    PENDING: '대기 중',
    REJECT: '거부',
    CANCEL: '취소',
    withdrawal: '출금 내역',
    prize: '보상 금액',
    noDeposit: '입금 내역 없음',
    noWithdrawl: '출금 내역 없음',
    success: '성공',
    error: '오류',
    warning: '경고',
    hidden: '비공개',
    show: '공개',
    amount: '금액',
    language: '언어',
    areYouSure: '확실한가요?',
    displaying: '내역의',
    comingSoon: '곧 출시 예정',
    goToLobby: '로비로 이동',
    level: '레벨',
    days: '일',
    hours: '시간',
    minutes: '분',
    seconds: '초',
    dinoCasino: '다이노 카지노',
    majorHiddenProgress: '주요 업데이트가 진행 중입니다.',
    experienceSoon: '곧 경험 하실 수 있습니다!',

    transactionWithdrawal: '곧 경험 하실 수 있습니다!',
    responsibleGaming: '책임성 있는 도박 ',
    otherLinks: '그 외',
    landingPage: '홈으로',
    supportLinks: '문의 관련 링크들',

    cashAmount: '현금 금액',
    EMERGENCY: '비상',
    MAINTENANCE: '유지 보수',
    Bonus: '보너스',
    WALLET: '내 지갑',
    noNotificationFound: '알림이 없습니다',
    notificationTitle: '알림',
    notificationRemovedSuccessfully: '알림이 삭제되었습니다',
    showUnRead: '읽지 않은 알림만 표시',
    markAll: '모두 읽음으로 표시',
    deleteAll: '모두 삭제',
    deleteNotificationSucces: '모든 알림이 삭제되었습니다',

    noBonusComingSoon: '곧 출시 예정입니다.',
    'UNIQUE-NUMBER-BONUS': '유니크 번호 보너스',
    noBonusFound: '사용 가능한 보너스가 없습니다',
    bonusTickets: '보너스티켓',
    couponNumber: '쿠폰 번호',
    bonus: '보너스',
    hotTimeBonus: '핫 타임 보너스',
    compensationBonus: '보상 보너스',
    reocurringBonus: '재충전 보너스',
    reoccuringBonus: '재충전 보너스',
    firstDepositBonus: '첫충전 보너스',
    noBonus: '보너스 없음',
    bonusLapsed: '보너스 만료',
    'HOT-TIME-BONUS': '핫 타임 보너스',
    'COMPENSATION-BONUS': '보상 보너스',
    'RE-OCCURING-DEPOSIT-BONUS': '재충전 보너스',
    'FIRST-DEPOSIT-BONUS': '첫충전 보너스',
    'UNIQUE-TICKET-BONUS': '유니크 티켓 보너스',
    notActive: '비활성',
    viewProgress: '진행 상황 보기',
    paybackBonus: '페이백 보너스',
    'PAYBACK-BONUS': '페이백-보너스',
    redeemAbleCoupons: '사용 가능한 쿠폰',
    nonApplicableCoupons: '사용 불가 쿠폰',
    bonusDetail: '보너스 혜택의 세부정보',
    addOnOddBonus: '추가 보너스를 제공합니다',
    bonusPercentage: '보너스 비율',
    bonusType: '보너스 종류',
    maxBonusLimit: '최대 보너스 한도',
    'WINNING-BONUS': '승리 보너스',
    'BETTING-BONUS': '베팅 보너스',
    'MIXED-MATCH': '조합 경기',
    'BET-COMPENSATION': '베팅 보상',
    rackbackBonus: '레이크백 보너스 ',
    periodicBonus: '주간 페이백',
    generalBonus: '일반 보너스',
    rackbackBonusEarned: '레이크백 내역',
    periodicBonusEarned: '주간 페이백 내역',
    generalBonusEarned: '일반 보너스 내역',
    claimedAmount: '적립된 금액',
    details: '세부 정보',
    availableRackbackEarning: '작립 가능 금액',
    endsIn: '종료까지 남은 시간',
    available: '출금 가능 금액',
    Min: '최소',
    Max: '최대',
    availableIn: '오픈까지 남은 시간',
    claimSuccess: '요청이 성공적으로 처리되었습니다.',
    dailyBonus: '데일리 페이백',
    weeklyBonus: '주간 페이백',
    biWeeklyBonus: '격주 페이백',
    monthlyBonus: '월간 페이백',

    forgotPassword: '비밀번호 분실',
    setting: '내 정보',
    changePassword: '비밀번호 변경',
    login: '로그인',
    profile: '내 프로필',
    transactionDeposit: '입금 내역',
    referredUsers: '내 추천인 현황',
    referAUser: '내 추천인 코드',
    referByLink: '링크를 통해 추천하기',
    noReferralUsers: '추천된 사용자를 찾을 수 없습니다.',
    promotionCash: '프로모션 캐시',
    checkKycStatus: '승인 상태',
    kycStatusTitle: '승인 상태',
    verified: '인증 완료',
    notVerified: '인증 실패',
    insufficientBalance: '잔액이 부족합니다!',
    depositableAmount: '입금 가능 금액',
    withdrawableAmount: '출금 가능 금액',
    depositWallet: '입금 내역',
    withdrawalWallet: '출금',
    wallet: '내 지갑',
    deposit: '입금 내역',
    withdraw: '출금',
    vaultAmount: '금고 금액',
    vault: '내 금고',
    vaultDepositSuccess: '입금 성공',
    vaultWithdrawSuccess: '출금 성공',
    verifyUser: '사용자 확인',
    requestCreatedSuccess: '요청이 성공적으로 생성되었습니다.',
    walletActionSourceAlreadyExists: '대기 중인 요청이 이미 존재합니다.',
    requestWithdraw: '출금 신청',
    requestDeposit: '입금 신청',
    mustBeMultipleOf10000: '금액은 10,000원 단위로 충전이 가능합니다',
    min10000Required: '최소 금액은 10,000원 이상이어야 합니다.',
    maxAmountEnteredis: '최대 금액은',
    requestErrorMessage: '요청 금액은 지갑 잔액보다 작거나 같아야 합니다.',
    mustBeInteger: '소수점 입력은 불가능합니다',
    applyCoupon: '쿠폰 사용',
    availThisCoupon: '입금 후 쿠폰을 등록하여 사용하세요',
    orAbove: '또는 이상',
    andGetBenefit: '그리고 혜택을 받으세요',
    back: '이전으로',
    selectCouponCode: '쿠폰 선택',
    couponIsApplied: '쿠폰이 사용 되었습니다',
    coupons: '쿠폰',
    lockedFunds: '잠긴 금액',
    markAnonymous: '익명으로 변경되었습니다',
    removeAnonymous: '익명이 해제되었습니다',

    cookieTitle: '쿠키',
    declineButton: '거절',
    acceptButton: '수락',
    cookieDeclinedMessage: '쾌적한 환경을 위해 쿠키를 수락해야 이 웹사이트를 계속 사용할 수 있습니다. 계속 진행하려면 쿠키를 수락해 주세요.',
    cookieDescription: "다이노에 오신 것을 환영합니다! 우리는 사용자 경험을 최적화하기 위해 쿠키를 사용합니다. '수락'을 클릭함으로써 이용에 동의합니다. '거절'을 클릭하여 거부할 수 있습니다.",
    registeredForRollingContest: '당신은 게임참가를 하기위해 공식 등록되었습니다 흥미로운 경험을 느껴보세요',
    alreadyRegister: '이미 등록된 사용자입니다.',
    noContestSchedule: '예정된 대회 없음',
    playerName: '플레이어',
    leadAmount: '금액',

    myVouchers: '내 쿠폰',
    noVoucherFound: '사용 가능한 쿠폰이 없습니다',
    voucherName: '쿠폰 이름',
    voucherUseLimit: '쿠폰 사용 가능 횟수',
    vouchers: '쿠폰',
    remainingRedeemptions: '남은 사용 횟수',

    tenanatAreBlocked: '관리자에 의해 차단되었습니다',
    blacklistedError: '로그인할 수 없습니다',
    kycNotComplete: '인증이 완료되지 않았습니다. 인증을 먼저 완료해 주세요',
    sessionExpired: '세션이 만료되었습니다',
    nicknameRequired: '사용할 닉네임을 입력해주세요',
    nickNameLabel: '닉네임',
    nickNamePlaceholder: '닉네임 입력',
    referralCodeRequired: '추천 코드가 필요합니다',
    referralCodePlaceholder: '추천 코드를 입력하세요',
    oldPasswordDidNotMatch: '이전 비밀번호가 일치하지 않습니다',
    withdrawlPasswordDoesNotMatch: '출금 비밀번호가 일치하지 않습니다',
    currencyRequired: '화폐를 종류를 선택해야 됩니다',
    amountRequired: '금액을 필수로 입력해야 됩니다',
    selectCurrency: '화폐를 선택하세요',
    currency: '화폐',
    enterAmountPlaceholder: '금액을 입력하세요',
    acceptTerms: '이용 약관에 동의합니다',
    usernameLabel: '아이디',
    passwordLabel: '비밀번호',
    oldPasswordLabel: '이전 비밀번호',
    newPasswordLabel: '새 비밀번호',
    confirmPasswordLabel: '비밀번호 확인',
    confirmNewPasswordLabel: '새 비밀번호 확인',
    idOfUserLabel: '아이디',
    legalNameLabel: '이름',
    nationalityLabel: '국적',
    dateOfBirthLabel: '생년월일',
    selectMonth: '월 선택',
    selectDay: '일 선택',
    selectYear: '년도 선택',
    phoneLabel: '전화번호',
    preferredCurrencyLabel: '화폐',
    bankNameLabel: '은행 명',
    accountNumberLabel: '계좌 번호',
    withdrawalPasswordLabel: '출금 비밀번호',
    reEnterwithdrawalPasswordLabel: '출금 비밀번호 재입력',
    signIn: '로그인',
    signUp: '회원가입',
    userIdPlaceholder: '아이디 입력',
    passwordRequired: '비밀번호 필요',
    oldPasswordRequired: '이전 비밀번호 필요',
    newPasswordRequired: '새 비밀번호 필요',
    accountFrozen: '귀하의 계정이 정지되었습니다.',
    userLoggedOut: '성공적으로 로그아웃 되었습니다',
    loggingIn: '제출 중입니다 잠시만 기다려 주세요...',
    selectNationality: '국적 선택',
    selectBankName: '은행명 선택',
    selectPreferredCurrency: '선호하는 화폐 선택',
    dontHaveAccount: '가입된 계정이 없으신가요?',
    registerAnAccount: '계정 등록',
    alreadyHaveAnAccount: '이미 가입된 계정이 있으신가요?',
    fillAllFields: '회원가입 전에 모든 필수 항목을 작성해주세요',
    userNamePlaceholder: '아이디 입력',
    min8Numbers: '최소 8자 이상이어야 합니다',
    passwordValidationMessage: '비밀번호는 최소 8자 이상이어야 하며, 대문자 1개, 숫자 1개, 특수문자 1개가 포함되어야 합니다. 공백과 기호는 사용할 수 없습니다.',
    withdrawlPasswordValidationMessage: '출금 비밀번호는 최소 8자 이상이어야 하며, 대문자, 숫자, 특수문자가 포함되어야 합니다. 공백과 기호는 사용할 수 없습니다.',
    passwordPlaceholder: '비밀번호 입력',
    confirmPasswordPlaceholder: '비밀번호 확인 입력',
    passwordWithdrawalPlaceholder: '출금 비밀번호 입력',
    oldPasswordPlaceholder: '이전 비밀번호 입력',
    newPasswordPlaceholder: '새 비밀번호 입력',
    confirmNewPasswordPlaceholder: '새 비밀번호 확인 입력',
    confirmPasswordWithdrawalPlaceholder: '출금 비밀번호 재입력',
    legalNamePlaceholder: '입력',
    phonePlaceholder: '전화번호 입력',
    searchPhonePlaceholder: '국가 검색',
    accountNumberPlaceholder: '계좌 번호 입력',
    userIdRequired: '아이디 필수로 입력해주세요',
    userIdInvalid: '알파벳과 숫자만 허용됩니다',
    confirmNewPasswordRequired: '새 비밀번호 확인 필수로 입력해주세요',
    confirmPasswordRequired: '비밀번호 확인 필수로 입력해주세요',
    passwordWithdrawalRequired: '출금 비밀번호 필수로 입력해주세요',
    confirmPasswordWithdrawalRequired: '출금 비밀번호 확인 필수로 입력해주세요',
    legalNameRequired: '이름 필수로 입력해주세요',
    phoneRequired: '전화번호 필수로 입력해주세요',
    dateOfBirthRequired: '생년월일 필수로 선택해주세요',
    userNameRequired: '아이디 필수로 입력해주세요',
    requiredAccountNumber: '계좌 번호 필수로 입력해주세요',
    countryRequired: '국적 필수로 선택해주세요',
    matchPassword: '비밀번호는 일치해야 합니다',
    matchWithdrawalPassword: '출금 비밀번호는 일치해야 합니다',
    onlyAlphabet: '알파벳만 허용됩니다',
    mustBe18: '등록하려면 만 18세 이상이어야 합니다',
    validDOB: '유효한 생년월일을 입력하세요',
    max20Characters: '최대 20자까지 입력 가능합니다',
    min3Characters: '최소 3자 이상을 입력해야 됩니다',
    min2Characters: '최소 2자 이상을 입력해야 됩니다',
    min4Characters: '최소 4자 이상을 입력해야 됩니다',
    min5Numbers: '최소 5자리(국제전화번호 포함) 이상을 입력해야 됩니다',
    spaceNotAllowed: '공백, 점, 쉼표(특수문자)는 허용되지 않습니다',
    onlySpaceNotAllowed: '공백은 허용되지 않습니다',
    max100Characters: '최대 100자까지 입력 가능합니다',
    max50Characters: '최대 50자까지 입력 가능합니다',
    max30Characters: '최대 30자까지 입력 가능합니다',
    max16Characters: '최대 16자까지 입력 가능합니다',
    max16Numbers: '다이얼 코드(국가 코드)를 포함하여 최대 16자리 숫자만 허용됩니다',
    preferredLanguageRequired: '선호하는 언어 필수로 선택해주세요',
    preferredCurrencyRequired: '선호하는 화폐 필수로 선택해주세요',
    bankNameRequired: '은행명 필수로 선택해주세요',
    nationalityRequired: '국적 필수로 선택해주세요',
    legalName: '알파벳과 공백만 허용됩니다',
    legalNameOneSpace: '단어 사이에 공백은 하나만 허용됩니다',
    selectPreferredLanguage: '선호하는 언어 선택',
    enterValidPhone: '유효한 전화번호를 입력하세요',
    userNameAllowed: '소문자만 허용되며 공백은 허용되지 않습니다',
    referralCodeInvalid: '유효하지 않은 추천 코드',
    userNameAlreadyExists: '이미 사용중인 아이디가 존재합니다!',
    incorrectCredentials: '비밀번호와 일치하는 사용자 이름이 없습니다!',
    UserCountryCodeWithPhoneAlreadyExists: '이 전화번호와 일치하는 사용자 이름이 이미 존재합니다!',
    loginErrorMessage: '계속하려면 로그인하세요',
    nickNamAlreadyExists: '닉네임이 이미 존재합니다.',
    logout: '로그아웃',
    logoutContent: '떠나기 전에 다양한 카지노 및 스포츠 프로모션을 꼭 확인해 보세요!',
    setNewPass: '새 비밀번호 설정',
    loginSuccess: '로그인 성공',
    signupSuccess: '회원가입 성공',
    updatedSuccess: '업데이트 성공',
    forgetPasswordRequest: '비밀번호를 잊으셨나요?',
    forgetPassword: '비밀번호 찾기',
    forgetPasswordRequestSent: '비밀번호 찾기 요청이 전송되었습니다!',
    itWillbeYourUpdatedWithdrawalPasswordAsWell: '출금 비밀번호 변경이 완료되었습니다.',
    registerYourAccount: '계정을 등록하세요!',
    isAnonymous: '익명으로 표시',
    markKnown: '알림으로 표시',
    alreadyLogin: '이미 로그인되어 있습니다',
    alreadyLoginMessage: '사용자가 이미 여러 IP에서 로그인되어 있습니다. 여기에서 로그인하시겠습니까?',

    favourites: '내 즐겨찾기',
    noFavouritesGames: '즐겨찾기 목록에 게임이 추가되지 않았습니다.',
    addToFavourites: '즐겨찾기에 추가하기',
    removeFromFavourites: '즐겨찾기에서 제거하기',
    addToFavSuccess: '즐겨찾기에 추가되었습니다',
    removeFromFavSuccess: '즐겨찾기에서 제거되었습니다',

    topMatch: '주요 경기',
    eventStartIn: '경기 시작 시간',
    betNotPlacedPleaseTryAgain: '이 경기에 대한 베팅을 할 수 없습니다. 다시 시도해 주세요.',
    topMatchesNotAvailable: '주요 경기가 없습니다.',
    goToEvent: '경기로 이동',
    hotCombo: '핫 콤보',
    liveMatchResult: '실시간 경기 결과',
    processing: '진행 중',
    favoriteRemoved: '즐겨찾기에 성공적으로 제거되었습니다',
    favoriteAdded: '즐겨찾기에 성공적으로 추가되었습니다',
    maxCustomMarketLimit: '단일 베팅 카트에서 최대 10개의 조합을 선택할 수 있습니다',
    betErrorHotCombo: '이 핫 콤보에 대해 베팅할 수 없습니다. 다른 핫 콤보로 시도하십시오',
    cashoutMessage: '캐쉬 아웃이 진행 중입니다. 자세한 정보는 알림을 확인하십시오',
    cashoutInprogress: '캐쉬 아웃 진행 중',
    outcomeSuspended: '결과가 일시 중단되었습니다',
    outcomeCancelled: '결과가 취소되었습니다',
    noEvents: '경기가 없습니다',
    noSportsAvailable: '베팅 가능한 스포츠가 없습니다',
    notStarted: '경기가 아직 시작되지 않았습니다',
    matchLive: '경기가 진행 중입니다',
    matchSuspended: '경기가 일시 중단되었습니다',
    ended: '경기가 종료되었습니다',
    matchClosed: '경기가 마감되었습니다',
    marketActive: '마켓이 활성화되었습니다',
    marketDeactive: '마켓이 비활성화되었습니다',
    marketSuspended: '마켓이 일시 중단되었습니다',
    marketSettled: '마켓이 정산되었습니다',
    marketCancelled: '마켓이 취소되었습니다',
    outcomeDeactive: '베팅한 결과가 비활성화되었습니다',
    outcomeActive: '베팅한 결과가 활성화되었습니다',
    outcomeSettled: '베팅한 결과가 정산되었습니다',
    sportsBook: '스포츠 북',
    sportBets: '스포츠 베팅',
    half_won: '절반 적중',
    half_lost: '절반 미적중',
    odds: '배당 합계',
    sports: '스포츠',
    eSports: 'E 스포츠',
    mySportsBets: '내 스포츠 베팅',
    noHotComboAvailable: '현재 핫 콤보를 사용할 수 없습니다',
    pleaseEnterStake: '배팅 금액을 입력해 주세요',
    single: '단일',
    combo: '조합',
    noBetsAdded: '베팅이 추가되지 않았습니다',
    possibleWin: '예상 당첨 금액',
    placeBet: '베팅하기',
    clearBetslip: '베팅 내역 지우기',
    vs: 'VS',
    liveEvents: '실시간 경기',
    topEvents: '인기 경기',
    upcomingEvents: '예정 경기',
    highlight: '하이라이트',
    schedule: '일정',
    live: '실시간',
    favorite: '즐겨 찾기',
    myBet: '베팅 내역',
    result: '결과',
    prev: '이전',
    next: '다음',
    today: '오늘',
    tomorrow: '내일',
    favorites: '즐겨 찾기',
    selectSport: '스포츠 선택',
    selectCountry: '국가 선택',
    selectLeague: '리그 선택',
    fromDate: '시작 날짜',
    toDate: '종료 날짜',
    winAmount: '당첨금액',
    stake: '베팅 금액',
    lostAmount: '손실 금액',
    cashoutAmount: '캐쉬 아웃 금액',
    pending: '대기 중',
    rejected: '거부됨',
    cashoutButton: '캐시아웃',
    betSlipId: '배팅 내역 ID',
    in_game: '게임 중',
    cashout: '캐쉬 아웃',
    won: '적중',
    lost: '미적중',
    refund: '취소',
    partial_won: '부분 적중',
    topLeague: '인기 리그',
    noLeagues: '리그를 찾을 수 없습니다',
    alternativeOddType: '대체 배당 유형 제공',
    areYouSureCashout: '캐쉬아웃 하시겠습니까?',
    alternateStakeButton: '대체 베팅 금액',
    reOfferButton: '베팅 정보가 변경되었습니다 재확인 바랍니다',
    alternativeStakeTitle: '대체 베팅 금액 제안',
    viewAllMarket: '모든 경기 보기',
    closed: '베팅 마감',
    removeClosedBet: '베팅 카트에서 종료된 경기를 제거해 주세요!',
    betSetting: '배당 설정',
    any: '배당 변경 허용',
    none: '배당 변경 안함',
    higher: '더 높은 배당 변경 허용',
    reset: '리셋',
    selectFromDate: '시작 날짜 선택',
    selectToDate: '종료 날짜 선택',
    customBet: '커스텀 베팅',
    calclutedOds: '배당 합계',
    removeMainBet: '베팅 카트에서 메인 베팅을 먼저 제거 해주세요',
    removeCustomBet: '베팅 카트에서 커스텀 베팅을 먼저 제거 해주세요',
    addSameCusomMarket: '동일한 경기의 커스텀 베팅을 추가하세요',
    enterStake: '베팅 금액을 입력하세요',
    main: '메인',
    custom: '커스텀',
    quickBet: '빠른 베팅',
    quickBetDesc: '빠른 베팅 모드가 활성화 되었습니다! 선택 항목을 클릭하면 즉시 베팅이 이루어집니다. 모든 베팅은 스포츠 베팅 내역에서 확인할 수 있습니다.',
    page: '페이지',
    quickBetIsActiveWith: '빠른 베팅이 활성화되었습니다',
    pleaseEnterStakeToActiveBet: '베팅을 활성화하려면 배팅 금액을 입력하세요',
    loginToPlaceBet: '베팅을 하려면 로그인하세요',
    tie: '무승부',
    addAtleasetTwoEvents: '베팅을 하려면 최소 두 개의 커스텀 베팅을 추가하세요',
    events: '경기',
    noEventsSchedule: '예정된 경기 일정 없음',
    betPlacedSuccess: '베팅이 처리 중입니다. 자세한 정보는 알림을 확인해 주세요',

    // new translation
    betBySports: '스포츠 베팅',
    sportsBonus: '스포츠 보너스',
    bonusRollProgress: '보너스 롤 진행 상황',
    active: '활성',
    clickToViewBonusProgress: '보너스 진행 상황 보려면 클릭',
    depositMoneyGetBonusBenefits: '보너스 혜택을 받으려면 돈을 입금하세요',
    bonusDetails: '내 보너스 진행상황',

    depositRolling: '입금 롤링',
    rollProgress: '롤 진행 상황',
    wageredAmount: '베팅된 금액',
    eligibleAmount: '적격 금액',
    transactionDate: '거래 날짜',
    transactionTime: '거래 시간',
    transactionAmount: '거래 금액',
    addOns: '추가 항목',
    rollBenefit: '롤 혜택',
    completedDepositRollings: '완료된 입금 롤링',
    ongoingDepositRollings: '진행 중인 입금 롤링',
    claimBenefits: '혜택 청구',
    claimedSuccess: '성공적으로 청구됨',
    resetFilter: '필터 초기화',
    amountSelected: '선택된 금액',

    nonWithdrawalableAmount: '입금 금액',
    withdrawalableAmount: '출금 가능 금액',
    bonusAmount: '보너스 금액',
    ongoingBonusRollings: '진행 중인 보너스 롤링',
    completedBonusRollings: '완료된 보너스 롤링',
    walletsRolling: '지갑 롤링',
    promotionsRollings: '프로모션 롤링',
    onGoingPromotionRollings: '진행 중인 프로모션 롤링',
    completedPromotionRollings: '완료된 프로모션 롤링',
    promotionProgress: '프로모션 진행 상황',
    payoutWalletAmount: '지불금 지갑 금액',

    // bonus
    activatedFrom: '다음에서 활성화됨',
    promotionCashRolling: '판촉 현금 롤링',
    bonusRolling: '보너스 롤링',
    rollOver: '롤오버',
    rollOverType: '롤오버 유형',
    allType: '모든 유형',
    allStatus: '모든 상태',
    complete: '완료',
    inprogress: '진행 중',
    notStartedRollStatus: '시작되지 않음',
    pleaseSelectRollType: '롤 유형을 선택하세요',
    totalClaimed: '총 청구',
    overview: '개요',
    'RACKBACK-BONUS': '래이크백 보너스',
    'DAILY-PERIODIC-PAYBACK-BONUS': '일일 주기적 페이백 보너스',
    'BI-WEEKLY-PERIODIC-PAYBACK-BONUS': '격주 주기적 페이백 보너스',
    'WEEKLY-PERIODIC-PAYBACK-BONUS': '주간 주기적 페이백 보너스',
    'MONTHLY-PERIODIC-PAYBACK-BONUS': '월간 주기적 페이백 보너스',
    claimAfter: '이후 청구',
    dailyLimitReached: '일일 한도 도달',
    weeklyLimitReached: '주간 한도 도달',
    bonusLimitReached: '보너스 한도 도달',
    monthlyLimitReached: '월 한도 도달',
    compensationTargetNotMet: '보상 목표를 달성하지 못함',
    claim: '청구',
    sportsPaybackBonus: '스포츠 페이백 보너스',
    // common
    player_: '플레이어_',

    vouchersRolling: '바우처 롤링',
    giftCardsRolling: '기프트 카드 롤링',
    seasonsRolling: '시즌 롤링',
    promotions: '프로모션',
    achievements: '업적'

  }
}

export default KOJSON
