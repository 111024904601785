import React from 'react'
import { IoIosInformationCircleOutline, IoIosArrowDown, IoIosArrowForward } from 'react-icons/io'

import { FaCheck, FaEye, FaImages, FaInfoCircle, FaRegTrashAlt, FaUser, FaUsers } from 'react-icons/fa'
import { GrPowerReset, GrStatusGood } from 'react-icons/gr'
import { AiOutlineTransaction } from 'react-icons/ai'
import { IoChevronForward, IoCloseOutline, IoWalletOutline } from 'react-icons/io5'
import { LuRefreshCcw } from 'react-icons/lu'
import { TfiTimer } from 'react-icons/tfi'
import { BiArrowBack, BiCheckDouble } from 'react-icons/bi'
import { MdOutlineDeleteSweep } from 'react-icons/md'
export const REACT_ICONS = {
  deleteAll: <MdOutlineDeleteSweep />,
  infoOutline: <IoIosInformationCircleOutline />,
  goBack: <BiArrowBack />,
  markAll: <BiCheckDouble />,
  gallery: <FaImages />,
  remove: <FaRegTrashAlt />,
  reset: <GrPowerReset />,
  userIcon: <FaUser />,
  arrowRight: <IoIosArrowForward />,
  info: <FaInfoCircle />,
  eye: <FaEye />,
  arrowDown: <IoIosArrowDown />,
  chevronRight: <IoChevronForward />,
  transactions: <AiOutlineTransaction />,
  wallet: <IoWalletOutline />,
  vault: <IoWalletOutline />,
  kycStatus: <GrStatusGood />,
  users: <FaUsers />,
  refresh: <LuRefreshCcw />,
  timer: <TfiTimer />,
  check: <FaCheck />,
  close: <IoCloseOutline />

}
